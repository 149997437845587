import { Autocomplete, Box, IconButton, styled, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { toast, ToastContainer } from "react-toastify";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
const ProduitsAtraiterListe = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [produits, setProduits] = useState([]);
  const { refreshToken } = useLogin();
  const { t } = useTranslation();
  const [filter, setFilter] = useState(localStorage?.getItem("order_treated") ? JSON.parse(localStorage?.getItem("order_treated")) : { display: t("forms.labels.par_date"), value: "" });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page")) || 1;
  const [modification, setModification] = useState(false);
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (page === 1) {
      setSearchParams({ page: 1 });
    }
    setLoading(true);
    Api.get(Urls.GET_PRODUITS_A_TRAITER, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: page === 1 ? 0 : (page - 1) * 50,
        ...(filter?.value && { ordering: filter?.value }),
        limit: 50,
      },
    })
      .then((res) => {
        setProduits(res?.data?.results);
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification(!modification);
          });
        } else {
          toast.error(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [modification, filter, page]);

  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton
            onClick={() => {
              setSearchParams({ page: page - 1 });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton
            onClick={() => {
              setSearchParams({ page: page + 1 });
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          >
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  const calculateDaysDiff = (date2) => {
    const date1obj = new Date();
    const date2obj = new Date(date2);
    const timeDiff = date2obj - date1obj;
    const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return dayDiff;
  };
  const options = [
    { display: t("forms.labels.par_date"), value: "" },
    { display: t("forms.labels.par_section"), value: "location_then_expiration" },
  ];
  const StyledAutocomplete = styled(Autocomplete)({
    width: 180,
    "& .MuiAutocomplete-inputRoot": {
      minHeight: "24px !important",
      height: "24px !imporant",
    },
    "& .MuiAutocomplete-input": {
      minHeight: "24px !important",
      height: "24px !imporant",
    },
    "& .MuiInputBase-root": {
      height: "24px !important",
      minHeight: "24px !important",
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "16px",
      height: "24px !important",
      minHeight: "24px",
      padding: "0 9px 0 6px",
      "& input": {
        padding: "0 4px",
        height: "24px !important",
      },
      "& fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23)",
      },
    },
  });

  return (
    <Box>
      <ToastContainer />
      {loading && <Loading />}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="12px" maxWidth={"520px"}>
          <Box sx={{ display: "flex", justifyContent: "start", width: "520px" }}>
            <StyledAutocomplete
              disableClearable
              options={options}
              value={filter}
              isOptionEqualToValue={(option, value) => option?.value === value.value}
              getOptionLabel={(e) => e?.display}
              onChange={(event, value) => {
                if (value !== null) {
                  setFilter(value);
                  localStorage.setItem("order_treated", JSON.stringify(value));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Langue"
                  sx={{
                    "& .MuiButtonBase-root": {
                      minHeight: "10px",
                      minWidth: "10px",
                    },
                    "& .MuiInputBase-root": {
                      minHeight: "24px",
                      padding: "0px 10px",
                    },
                    "& .MuiInputBase-input": {
                      minHeight: "24px",
                      padding: "0px 10px",
                    },
                    "& .MuiInputBase-inputMultiline": {
                      borderRadius: "6px",
                      fontSize: "16px",
                      lineHeight: "22.4px",
                      fontWeight: "600",
                      padding: "10px 10px",
                    },
                    "& .MuiOutlinedInput-root": {
                      minHeight: "24px",
                      borderRadius: "6px",
                      fontSize: "16px",
                      lineHeight: "22.4px",
                      fontWeight: "500",
                      padding: "0px 10px",

                      "& fieldset": {
                        borderColor: "#DEDFE4",
                        borderRadius: "6px",
                        fontSize: "16px",
                        lineHeight: "22.4px",
                        fontWeight: "500",
                        padding: "0px 10px",
                      },
                      "&:placeholder fieldset": {
                        color: "#DEDFE4",
                      },
                      "&:hover fieldset": {
                        border: `2px solid black`,
                      },
                      "&.Mui-focused fieldset": {
                        border: `2px solid black`,
                      },
                    },
                  }}
                  name="autocomplete"
                  inputProps={{
                    ...params.inputProps,
                    style: { height: "30px" },
                  }}
                />
              )}
            />
          </Box>
          {produits?.map((prod) => (
            <Box
              onClick={() => {
                navigate("/employee/gestion-des-traiter/liste/" + prod?.upc);
              }}
              sx={{ cursor: "pointer" }}
              display="flex"
              gap="20px"
              alignItems="start"
              backgroundColor="rgba(211,211,211 , 0.5)"
              borderRadius="16px"
              padding="10px"
            >
              <Box sx={{ flex: "1" }}>
                <img style={{ height: "auto", width: "auto", aspectRatio: "1/1" }} src={prod?.use_admin_image ? (prod.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + prod.product_image?.product_image : imagePlaceholder) : prod.private_product_image ? process.env.REACT_APP_IMAGE_URL + prod.private_product_image : imagePlaceholder} alt="produit" />
              </Box>
              <Box flex={1} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  UPC: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.upc}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.designation}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.format")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.format}</Typography>
                </Typography>
                <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>
                  {t("forms.labels.localisation")}: <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.location ? prod?.location : "-"}</Typography>
                </Typography>
                <Box>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{t("forms.labels.expiration_date")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? moment(prod?.expiration_date).format("MMMM DD, yyyy") : "-"}</Typography>
                </Box>
                <Box>
                  <Typography sx={{ display: "inline", fontSize: "16px", fontWeight: "600", color: "black" }}>{t("expire_d")}:</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "600", color: colors.main }}>{prod?.expiration_date ? calculateDaysDiff(prod?.expiration_date) + " " + t("days") : "-"}</Typography>
                </Box>
              </Box>
            </Box>
          ))}
          <Box marginBottom="24px">
            <Pagination />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ProduitsAtraiterListe;
