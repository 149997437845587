export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  boxShadow: 24,
  padding: { xs: "36px 20px", sm: "36px 20px", md: "36px 34px", lg: "36px 34px" },
  maxWidth: { xs: "300px", sm: "400px", md: "600px", lg: "700px" },
  minWidth: { xs: "250px", sm: "400px", md: "600px", lg: "700px" },
  PointerEvent: "auto",
  zIndex: 0,
  overflowY: "scroll",
};
