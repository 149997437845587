import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import enLocale from "date-fns/locale/en-AU";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { CloseOutlined, SelectAll, UploadFile } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import moment from "moment";
const RecallNotificationDetails = () => {
  const [error, setError] = useState("");
  const { refreshToken } = useLogin();
  const { user } = useAuthContext();
  const refresh_token = user?.refresh;
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [product, setProduct] = useState();
  const access_token = user?.access;
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [modification, setModification] = useState({ type: "refresh-dbs", state: false, count: 0, data: null });
  const { t } = useTranslation();
  const [initial_values, setInitialValues] = useState({
    upc: "",
    format: "",
    designation: "",
    note: "",
    expiration_date: null,
  });
  //validation schema
  const validationSchema = yup.object({
    upc: yup?.string().required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    note: yup?.string().required(t("validation_required")),
    expiration_date: yup?.date().required(t("validation_required")),
  });
  useEffect(() => {
    Api?.get(Urls?.GetRecallNotificationByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setProduct(res?.data);
        setInitialValues({
          upc: res?.data?.upc,
          format: res?.data?.format,
          designation: res?.data?.designation,
          note: res?.data?.note,
          expiration_date: res?.data?.expiration_date,
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setUpdate(!update);
          });
        } else {
          setError(err?.response?.data?.details);
          setLoading(false);
        }
      });
  }, [update]);

  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Formik initialValues={initial_values} enableReinitialize>
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        UPC / PLU
                      </Typography>
                    </Box>
                    <Box>
                      <Field disabled={true} name="upc" type="input" placeholder="UPC" as={TextField} />
                      <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.format")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field disabled={true} name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.designation")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field multiline rows={2} disabled={true} name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                      <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.expiration_date")}
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <LocalizationProvider adapterLocale={enLocale} dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={true}
                        inputFormat="yyyy-MM-dd"
                        value={values.expiration_date}
                        minDate={new Date()}
                        InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                        onChange={(value) => {
                          setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="start" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.note")}
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        fullWidth
                        sx={{ width: { xs: "300px", sm: "400px", md: "600px", lg: "600px" } }}
                        disabled={true}
                        columns={8}
                        value={values?.note}
                        rows={10}
                        multiline
                        onChange={(e) => {
                          setFieldValue("note", e.target?.value);
                        }}
                        type="input"
                        placeholder={t("forms.labels.note")}
                      />
                      <ErrorMessage name="note" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="start" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.created_at")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "text_md_black", fontWeight: "600" }}>{product?.created_at ? moment(product?.created_at).format("DD MMMM yyyy") : "-"}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="start" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.created_by")}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: "text_md_black", fontWeight: "600" }}>{product?.created_by}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
export default RecallNotificationDetails;
