import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import i18next from "i18next";

const CustomLanguageSelector = () => {
  const [currentLangCode, setCurrentLangCode] = useState(localStorage.getItem("i18nextLng")?.toUpperCase());
  const handleChangeLang = (lang) => {
    if (lang === "FR") {
      i18next.changeLanguage("fr");
      setCurrentLangCode("FR");
    }
    if (lang === "EN") {
      i18next.changeLanguage("en");
      setCurrentLangCode("EN");
    }
    if (lang === "ES") {
      i18next.changeLanguage("es");
      setCurrentLangCode("ES");
    }
    window.location.reload();
  };
  return (
    <Box position="relative" margin="0px">
      <Box display="flex" gap="6px">
        <Box>
          <IconButton onClick={() => handleChangeLang("FR")} sx={currentLangCode === "FR" ? { backgroundColor: "white", color: "black" } : { backgroundColor: "black", color: "white" }}>
            <Typography sx={{ fontWeight: "600" }}>FR</Typography>
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => handleChangeLang("EN")} sx={currentLangCode === "EN" ? { backgroundColor: "white", color: "black" } : { backgroundColor: "black", color: "white" }}>
            <Typography sx={{ fontWeight: "600" }}>EN</Typography>
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => handleChangeLang("ES")} sx={currentLangCode === "ES" ? { backgroundColor: "white", color: "black" } : { backgroundColor: "black", color: "white" }}>
            <Typography sx={{ fontWeight: "600" }}>ES</Typography>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
export default CustomLanguageSelector;
