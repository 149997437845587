import React from "react";
import { InputLabel, styled } from "@mui/material";

function FormikErrorText({ children, color, width }) {
  const ErrorText = styled(InputLabel)({
    // marginTop: "0.3rem",
    color: color ? color : "white",
    fontSize: "15px",
    maxWidth: width,
  });
  return <ErrorText>{children}</ErrorText>;
}

export default FormikErrorText;
