export const columns_actions_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DÉSIGNATION",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "15%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TRAITÉ LE",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "TRAITÉ PAR",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "TYPE D'ACTION",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "action",
  },
];
export const columns_actions_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DESIGNATION",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "15%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TREATED ON",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "TREATED BY",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "ACTION TYPE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "action",
  },
];
export const columns_actions_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DISEÑACIÓN",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMATO",
    width: "15%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "TRATADO EL",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "TRATADO POR",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "TIPO DE ACCIÓN",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "action",
  },
];
