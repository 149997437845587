import { Alert, Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import EditIcon from "@mui/icons-material/Edit";
import { useLogin } from "../../../hooks/useLogin";
import { employe_columns_en, employe_columns_fr, employe_columns_es } from "../../../columns/EmloyeInfosColumns";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import debounce from "debounce";
import { useTranslation } from "react-i18next";
import { columns_users_actions_en, columns_users_actions_es, columns_users_actions_fr } from "../../../columns/usersActionsColumns";
import { action_types_en, action_types_es, action_types_fr } from "../../../assets/data/ActionTypes";
const UsersLogs = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  let refresh_token = user?.refresh;
  let limit = 25;
  const { profile } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { refreshToken } = useLogin();
  const [response, setResponse] = useState();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");

  const [searchUser, setSearchUser] = useState("");
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const [error, setError] = useState("");
  const [enterprise, setEnterprise] = useState("");
  const [searchEnterprise, setSearchEnterprise] = useState("");
  let action_types = lang === "fr" ? action_types_fr : lang === "en" ? action_types_en : lang === "es" ? action_types_es : {};
  const [modification, setModification] = useState(false);
  const columns = lang === "fr" ? columns_users_actions_fr : lang === "en" ? columns_users_actions_en : columns_users_actions_es;
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GetUsersLogs(profile), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit,
        ...(sorting?.name && { order_by: sorting?.name }),
        ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, upc: element?.product?.upc, created_at: element?.created_at, treated_by: element?.treated_by, action: action_types[`${element?.action}`] };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  }, [modification, sorting]);
  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            element = { id: element?.id, upc: element?.product?.upc, created_at: element?.created_at, treated_by: element?.treated_by, action: action_types[`${element?.action}`] };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  };
  const Buttons = ({ id }) => {
    return null;
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((enterprise) => {
      setSearchEnterprise(enterprise);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(enterprise);
  }, [enterprise]);
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default UsersLogs;
