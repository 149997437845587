import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { CloseOutlined, SelectAll, UploadFile } from "@mui/icons-material";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const AjoutProduit = () => {
  const [limit, setLimit] = useState({ state: true, number: 10 });
  const [error, setError] = useState("");
  const { refreshToken } = useLogin();
  const { user } = useAuthContext();
  const refresh_token = user?.refresh;
  const [selectedDb, setSelectedDb] = useState(null);
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const [errorDBS, setErrorDBS] = useState("");
  const [bds, setBds] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [bdsMasterCopy, setDbsMasterCopy] = useState([]);
  const [faillureAdding, setFaillureAdding] = useState([]);
  const [successAdding, setSuccessAdding] = useState([]);
  const [selectAllBds, setSelectAllBds] = useState(false);
  const [date, setDate] = useState(false);
  const [departement, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const access_token = user?.access;
  const [loading, setLoading] = useState({
    loadingDbsMarchand: false,
    loaded: false,
  });
  const [noDispo, setNoDispo] = useState(false);

  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const [modification, setModification] = useState({ type: "refresh-dbs", state: false, count: 0, data: null });
  const { t } = useTranslation();
  const initial_values = {
    upc: "",
    format: "",
    use_admin: true,
    designation: "",
    dep: null,
    category: categories[0],
    category_value: categories[0]?.value,
    sub_dep: null,
    localisation: "",
    prix: "",
    no_date: false,
    empty_shelf: false,
    active: false,
    prix_red: "",
    expiration_date: null,
    fournisseur: "",
    image: null,
    alert: 30,
    bd: null,
  };
  //validation schema
  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };
  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };
  const validationSchemaAllBds = yup.object({
    upc: yup?.string().required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),

    image: yup
      .mixed()
      .test("fileType", "Invalid file type", (file) => (file !== null ? validateFileType(file) : true))
      .test("fileSize", "File size exceeds 5MB", (file) => (file !== null ? validateFileSize(file) : true))
      .nullable(),
  });
  const validationSchema = yup.object({
    upc: yup?.string().required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),
    bd: yup
      .object({
        id: yup?.string().required(t("validation_required")),
      })
      .required(t("validation_required")),
    image: yup
      .mixed()
      .test("fileType", "Invalid file type", (file) => (file !== null ? validateFileType(file) : true))
      .test("fileSize", "File size exceeds 5MB", (file) => (file !== null ? validateFileSize(file) : true))
      .nullable(),
  });
  useEffect(() => {
    if (selectedDb?.franchise_id) {
      Api.get(Urls?.GetFranchise(selectedDb?.franchise_id), {
        headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setDepartments(res?.data?.departments);
          setSubDepartments(res?.data?.sub_departments);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              setUpdate(!update);
            });
          } else {
            setError({ details: err?.response?.data?.detail });
          }
        });
    }
  }, [update, selectedDb]);
  useEffect(() => {
    if (!loading?.loadingDbsMarchand || modification?.type === "refresh-dbs-marchand") {
      Api.get(Urls?.GET_BDS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: limit?.number,
        },
      })

        .then((res) => {
          setLimit({ number: res?.data?.count, state: false });
          setLoading({
            loadingDbsMarchand: false,
            loaded: true,
          });
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              console.log(res, "new user");
              setModification({ type: "refresh-dbs-marchand", state: !modification?.state, count: modification?.count + 1, data: null });
            });
          } else {
            setError(err?.response?.data?.details);
          }
        });
    }
  }, [modification]);
  useEffect(() => {
    if (modification?.type === "submit" && modification?.data) {
      let values = modification?.data?.value;
      let id = modification?.data?.bd;
      handleSubmit(values, id);
    }
  }, [modification]);

  useEffect(() => {
    if (modification?.type === "pagination-dbs" || limit?.state === false) {
      handleGetAllDbs();
    }
  }, [modification, limit]);

  //HANDLE SBUMIT CREATE PRODUCT
  const [progressFile, setProgressFile] = useState(0);
  var config = {
    headers: { "Content-Type": "multipart/form-data", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressFile(percentCompleted);
    },
  };
  const handleSubmit = (values, db) => {
    setErrorDBS("");
    setError(null);
    setLoadingSubmit(true);
    setFaillureAdding([]);
    Api.post(
      Urls.ADD_PRODUCT,
      {
        upc: values?.upc,
        format: values?.format,
        category: values?.category_value,
        use_admin_image: values.use_admin,
        price: values?.prix,
        price_reduction: values?.prix_red,
        active: values?.active,
        empty_shelf: values?.empty_shelf,
        alert_in_days: values?.alert,
        active: values?.active,
        ...(!values?.empty_shelf && !values.no_date && { expiration_date: date ? "2099-12-31" : values.expiration_date ? values?.expiration_date : "" }),
        product_db_id: db ? db : values?.bd?.id,
        location: values?.localisation,
        designation: values?.designation,
        product_image: values?.image,
      },
      config
    )
      .then((res) => {
        setSuccess(t("forms.success.add"));
        setFaillureAdding(faillureAdding?.concat(res?.data?.result?.failure_product_db_list));
        setSuccessAdding(successAdding?.concat(res?.data?.result?.success_product_db_list));
        setLoadingSubmit(false);
        setProgressFile(0);
        console.log(res, "res");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: { values: values } };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
          setLoadingSubmit(false);
          setProgressFile(0);
        }
      });
  };

  const submitAllItems = async (values) => {
    console.log("test", bds);
    let success_array = [];
    let failled_array = [];
    for (let index = 0; index < bds?.length; index++) {
      const element = bds[index];
      console.log(element?.is_master, element?.id);
      if (element?.is_master) {
        setErrorDBS("");
        setError(null);
        setLoadingSubmit(true);
        setFaillureAdding([]);
        setTimeout(() => {
          Api.post(
            Urls.ADD_PRODUCT,
            {
              upc: values?.upc,
              format: values?.format,
              category: values?.category_value,
              use_admin_image: values.use_admin,
              price: values?.prix,
              price_reduction: values?.prix_red,
              active: values?.active,
              empty_shelf: values?.empty_shelf,
              alert_in_days: values?.alert,
              active: values?.active,
              ...(!values?.empty_shelf && !values.no_date && { expiration_date: date ? "2099-12-31" : values.expiration_date ? values?.expiration_date : "" }),
              product_db_id: element.id,
              location: values?.localisation,
              designation: values?.designation,
              product_image: values?.image,
            },
            config
          )
            .then((res) => {
              setSuccess(t("forms.success.add"));
              success_array = success_array.concat(res?.data?.result?.success_product_db_list);
              failled_array = failled_array.concat(res?.data?.result?.failure_product_db_list);
              setSuccessAdding(success_array);
              setFaillureAdding(failled_array);
              setLoadingSubmit(false);
              setProgressFile(0);
              console.log(res, "res");
              setTimeout(() => {
                setSuccess("");
              }, 3000);
            })
            .catch((err) => {
              if (err?.response?.data?.code === "token_not_valid") {
                refreshToken(refresh_token).then((res) => {
                  const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: { values: values, bd: element?.id } };
                  setModification(newState);
                });
              } else {
                handleError(err?.response?.data);
                setLoadingSubmit(false);
                setProgressFile(0);
              }
            });
        }, 2000);
      }
    }
    console.log(success_array, failled_array);
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      upc: error?.upc,
      format: error?.format,
      department: error?.department,
      sub_department: error?.sub_department,
      price: error?.price,
      price_reduction: error?.price_reduction,
      alert_in_days: error?.alert_in_days,
      supplier: error?.supplier,
      product_db_id: error?.product_db_id,
      location: error?.location,
      designation: error?.designation,
      product_image: error?.product_image,
    });
  }

  const handleGetAllDbs = () => {
    Api.get(Urls?.GET_BDS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit?.number,
      },
    })

      .then((res) => {
        setBds((data) => {
          return res?.data?.results;
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification({ type: "pagination-dbs", state: !modification?.state, count: modification?.count + 1, data: null });
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  };
  const FileUploadTextField = ({ field, form, ...props }) => {
    const handleFileChange = (event) => {
      form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
      <TextField
        {...props}
        id={field.name}
        type="text"
        value={field.value ? field.value.name : ""}
        InputProps={{
          startAdornment: (
            <label htmlFor={field.name}>
              <input type="file" id={field.name} name={field.name} style={{ display: "none" }} onChange={handleFileChange} accept="image/*" />
              <Button sx={{ borderRadius: "0px", width: "50px", marginLeft: "-10px", borderRight: "1px solid #dedfe4" }} component="span" type="file">
                <UploadFile />
              </Button>
            </label>
          ),
        }}
      />
    );
  };

  return (
    <Box>
      {!loading.loaded && <Loading />}
      {loading?.loaded && (
        <Formik initialValues={initial_values} validationSchema={selectAllBds ? validationSchemaAllBds : validationSchema} enableReinitialize onSubmit={(values) => (selectAllBds ? submitAllItems(values) : handleSubmit(values))}>
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="16px">
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        UPC / PLU
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="upc" type="input" placeholder="UPC" as={TextField} />
                      <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="930px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, flexWrap: "wrap" }} justifyContent={"space-between"} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.bd")}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
                    <Autocomplete
                      sx={{ minWidth: "262px", maxWidth: "263px" }}
                      options={bds}
                      disabled={selectAllBds}
                      value={values?.bd}
                      getOptionLabel={(e) => e?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue("bd", value);
                          setSelectedDb(value);
                          setFieldValue("dep", null);
                          setFieldValue("sub_dep", null);
                        } else {
                          setFieldValue("bd", null);
                          setFieldValue("dep", null);
                          setFieldValue("sub_dep", null);
                          setSelectedDb(null);
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.bd")} />}
                    />
                  </Box>
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      maxWidth: "300px",
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={selectAllBds}
                        value={selectAllBds}
                        onChange={(e) => {
                          setSelectAllBds(e.target.checked);
                        }}
                      />
                    }
                    label={t("forms.labels.all_bds")}
                  />
                  <ErrorMessage name="bd.id" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.designation")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                      <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.format")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.localisation")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="localisation" placeholder={t("forms.labels.localisation")} as={TextField} />
                      <ErrorMessage name="localisation" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="600px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.category")}
                    </Typography>
                  </Box>
                  <Box>
                    <Autocomplete
                      sx={{ minWidth: "262px", maxWidth: "263px" }}
                      options={categories}
                      disableClearable
                      value={values.category}
                      getOptionLabel={(e) => e?.name}
                      isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue("category_value", value?.value);
                          setFieldValue("category", value);
                        } else {
                          setFieldValue("category", null);
                          setFieldValue("category_value", "");
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                    />
                  </Box>
                  <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
                </Box>
                <Box maxWidth="755px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.expiration_date")}
                    </Typography>
                  </Box>
                  <Box flex={1.2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="yyyy-MM-dd"
                        value={values.expiration_date}
                        minDate={new Date()}
                        disabled={noDispo || date}
                        InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                        onChange={(value) => {
                          setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      maxWidth: "300px",
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={date && !noDispo}
                        value={date && !noDispo}
                        onChange={(e) => {
                          setDate(e.target.checked);
                        }}
                      />
                    }
                    label="2099-12-31"
                  />
                </Box>

                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.image")}
                      </Typography>
                    </Box>
                    <Box flex={1.2}>
                      <Field autoComplete="off" name="image" placeholder="Choose a file" id="icon" component={FileUploadTextField} fullWidth />
                      <ErrorMessage name="image" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.prix")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="prix" placeholder={t("forms.labels.prix")} as={TextField} />
                      <ErrorMessage name="prix" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>
                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.prix_red")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="prix_red" type="input" placeholder={t("forms.labels.prix_red")} as={TextField} />
                      <ErrorMessage name="prix_red" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                <Box maxWidth="600px">
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.alert")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field type="number" name="alert" placeholder={t("forms.labels.alert")} as={TextField} />
                      <ErrorMessage name="alert" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                <FormControlLabel
                  sx={{
                    fontWeight: "600",
                    color: colors?.main,
                    fontSize: "18px",
                  }}
                  control={
                    <Checkbox
                      checked={values?.active}
                      value={values?.active}
                      onChange={(e) => {
                        setFieldValue("active", e.target?.checked);
                      }}
                    />
                  }
                  label="ACTIF"
                />

                <FormControlLabel
                  sx={{
                    fontWeight: "600",
                    color: colors?.main,
                    fontSize: "18px",
                  }}
                  control={
                    <Checkbox
                      checked={values?.no_date}
                      value={values?.no_date}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("expiration_date", null);
                          setFieldValue("empty_shelf", false);
                        }
                        setNoDispo(e.target.checked);
                        setFieldValue("no_date", e.target.checked);
                      }}
                    />
                  }
                  label={t("forms.labels.date_non_disponible")}
                />
                <FormControlLabel
                  sx={{
                    fontWeight: "600",
                    color: colors?.main,
                    fontSize: "18px",
                  }}
                  control={
                    <Checkbox
                      checked={values?.empty_shelf}
                      value={values?.empty_shelf}
                      onChange={(e) => {
                        setFieldValue("empty_shelf", e.target.checked);

                        if (e.target.checked) {
                          setFieldValue("no_date", false);
                        }
                        setNoDispo(e.target.checked);
                        setFieldValue("expiration_date", null);
                      }}
                    />
                  }
                  label={t("forms.labels.tablette_vide")}
                />
                <FormControlLabel
                  sx={{
                    fontWeight: "600",
                    color: colors?.main,
                    fontSize: "18px",
                  }}
                  control={
                    <Checkbox
                      checked={values?.use_admin}
                      value={values?.use_admin}
                      onChange={(e) => {
                        setFieldValue("use_admin", e.target?.checked);
                      }}
                    />
                  }
                  label="USE ADMIN PIC"
                />
                {errorDBS && (
                  <FormikErrorText color={colors?.main} width="500px">
                    {errorDBS}
                  </FormikErrorText>
                )}
                {success && <Alert severity="success">{success}</Alert>}
                {error && (
                  <Alert severity="error">
                    <Box>
                      <List>
                        {error?.upc && <ListItem>UPC: {error?.upc}</ListItem>}
                        {error?.format && (
                          <ListItem>
                            {t("forms.labels.format")} : {error?.format}
                          </ListItem>
                        )}
                        {error?.designation && (
                          <ListItem>
                            {t("forms.labels.designation")} : {error?.designation}
                          </ListItem>
                        )}
                        {error?.supplier && (
                          <ListItem>
                            {t("forms.labels.fournisseur")} : {error?.supplier}
                          </ListItem>
                        )}
                        {error?.product_image && <ListItem>IMAGE : {error?.product_image}</ListItem>}
                        {error?.price && (
                          <ListItem>
                            {t("forms.labels.prix")} : {error?.price}
                          </ListItem>
                        )}
                        {error?.price_reduction && (
                          <ListItem>
                            {t("forms.labels.prix_red")} : {error?.price_reduction}
                          </ListItem>
                        )}
                        {error?.product_db_id && (
                          <ListItem>
                            {t("forms.labels.bd")} : {error?.product_db_id}
                          </ListItem>
                        )}
                        {error?.alert_in_days && (
                          <ListItem>
                            {t("forms.labels.alert")} : {error?.alert_in_days}
                          </ListItem>
                        )}
                        {error?.department && (
                          <ListItem>
                            {t("forms.labels.departement")} : {error?.department}
                          </ListItem>
                        )}
                        {error?.sub_department && (
                          <ListItem>
                            {t("forms.labels.sous-dep")} : {error?.sub_department}
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </Alert>
                )}
                {successAdding?.length > 0 && (
                  <Box padding="16px" borderRadius="8px" backgroundColor="rgba(144,238,144,0.7)" maxWidth="500px" display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="text_md_black">
                        <strong>{t("forms.success.add")}</strong>
                      </Typography>
                      <IconButton onClick={() => setSuccessAdding([])}>
                        <CloseOutlined sx={{ color: "black" }} />
                      </IconButton>
                    </Box>
                    {successAdding?.map((success) => (
                      <Box display="flex" gap="8px" flexWrap="wrap">
                        <Typography variant="text_sm_black">
                          <strong>- {t("forms.labels.bd")}</strong> : {success.name}{" "}
                        </Typography>
                        <Typography variant="text_sm_black">
                          <strong>- IS MASTER</strong> : {success.is_master ? "YES" : "NO"}{" "}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                {faillureAdding?.length > 0 && (
                  <Box padding="16px" borderRadius="8px" backgroundColor={colors?.main} maxWidth="500px" display="flex" flexDirection="column" gap="10px">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="text_md_white">
                        <strong>{t("forms.faillure.failed")}</strong>
                      </Typography>
                      <IconButton onClick={() => setFaillureAdding([])}>
                        <CloseOutlined sx={{ color: "white" }} />
                      </IconButton>
                    </Box>
                    {faillureAdding?.map((failed) => (
                      <Box display="flex" gap="8px" flexWrap="wrap">
                        <Typography variant="text_sm_white">
                          <strong>- {t("forms.labels.bd")}</strong> : {failed.name}{" "}
                        </Typography>
                        <Typography variant="text_sm_white">
                          <strong>- IS MASTER</strong> : {failed.is_master ? "YES" : "NO"}{" "}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box display="flex" gap="10px" alignItems="center">
                  <Button disabled={loadingSubmit} type="submit" variant="primary">
                    {t("forms.submit.save")}
                  </Button>
                  {progressFile !== 0 && (
                    <Box display="flex" alignItems="center" gap="5px">
                      <Loading color={colors?.main} />
                      <Typography color={colors?.main}>{progressFile}%</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
export default AjoutProduit;
