import { Alert, Avatar, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../../assets/upload-icon.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { toast } from "react-toastify";
import { useLogin } from "../../../hooks/useLogin";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function DetailsImage() {
  const [files, setFiles] = useState();
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const { refreshToken } = useLogin();
  const [update, setUpdate] = useState(false);
  const [updateDelete, setUpdateDelete] = useState({ state: false, active: false });
  const navigate = useNavigate();
  const location = useLocation();
  const url = location?.state?.url;
  const { id } = useParams();
  const [drag, setDrag] = useState(false);
  const { t } = useTranslation();
  const handleDrop = () => {
    setDrag(false);
  };
  useEffect(() => {
    if (!location?.state?.url) {
      navigate("/admin/gestion-des-images/liste");
    }
  }, [location]);
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const DeleteFile = () => {
    Api.delete(Urls.DeleteImages(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        navigate("/admin/gestion-des-images/liste");
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdateDelete({ state: !updateDelete?.state, active: true });
          });
        } else {
          setError(err?.response?.data?.detail);
        }
      });
  };
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);

  var configsUpdateFiles = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const UpdateFiles = () => {
    setSuccess("");
    setError("");
    Api.post(
      Urls?.UpdateImages(id),
      {
        product_image: files,
      },
      configsUpdateFiles
    )
      .then((res) => {
        setProgressUpdatePic(0);
        setSuccess(t("forms.success.update"));
        setTimeout(() => {
          setSuccess("");
          navigate("/admin/gestion-des-images/liste");
        }, 2000);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdate(!update);
          });
        } else {
          setError(err?.response?.data?.detail);
          setProgressUpdatePic(0);
        }
      });
  };
  useEffect(() => {
    if (files) {
      UpdateFiles();
    }
  }, [update]);
  useEffect(() => {
    if (updateDelete?.active) {
      DeleteFile();
    }
  }, [updateDelete]);
  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px">
        <Avatar variant="square" src={files ? URL.createObjectURL(files) : url} sx={{ width: { xs: "250px", sm: "300px", md: "300px", lg: "300px" }, height: "auto" }} />
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
          <input
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            multiple
            accept="image/jpeg , image/png , image/jpg"
            onChange={(event) => {
              if (event?.target?.files[0]) setFiles(event?.target?.files[0]);
            }}
            style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute", cursor: "pointer" }}
            id="avatar-input"
            type="file"
          />
          <Box justifyContent="center" display="flex" sx={{ cursor: "pointer" }}>
            <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="2px dashed #DBDADE" borderRadius="6px">
              <img src={uploadIcon} alt="upload" />
              <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                {/* {!files.length && "Drop files here or click to upload"}
                                {files.length > 0 && (
                                  <ul>
                                    {files.map((file, index) => (
                                      <li key={index}>{file.name}</li>
                                    ))}
                                  </ul>
                                )} */}
                {t("forms.labels.drop_files")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="16px 0px"></Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              UpdateFiles();
            }}
            disabled={progressUpdatePic > 0}
          >
            {t("forms.submit.save")} {progressUpdatePic > 0 && <CircularProgress size={"small"} />}
          </Button>
        </Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              DeleteFile();
            }}
          >
            {t("forms.submit.delete")} {progressUpdatePic > 0 && <CircularProgress size={"small"} />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
export default DetailsImage;
