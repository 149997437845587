import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { useParams } from "react-router-dom";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const ProduitDetails = () => {
  const { refreshToken } = useLogin();
  const { user } = useAuthContext();
  const refresh_token = user?.refresh;
  const { id } = useParams();
  const access_token = user?.access;
  const [produit, setProduit] = useState({});
  const lang = localStorage?.getItem("i18nextLng");

  const [loading, setLoading] = useState(true);
  const [modification, setModification] = useState(false);
  const { t } = useTranslation();
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;

  useEffect(() => {
    Api.get(Urls?.GetProdByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setProduit(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        } else {
          setLoading(false);
        }
      });
  }, [modification]);

  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  UPC / PLU :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.upc}</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.format")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.format ? produit?.format : "-"}</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.designation")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.designation ? produit?.designation : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.departement")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.department_name ? produit?.department_name : produit?.sub_department_number}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.sous-dep")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.sub_department_name ? produit?.sub_department_name : produit?.sub_department_number}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.localisation")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.location ? produit?.location : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.prix")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.price ? produit?.price : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.prix_red")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.price_reduction ? produit?.price_reduction : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.fournisseur")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.supplier ? produit?.supplier : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.alert")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.alert_in_days ? produit?.alert_in_days : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.category")} :
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">
                  <strong>{produit?.category ? categories?.filter((cat) => cat?.value === produit?.category)[0]?.name : "-"}</strong>
                </Typography>{" "}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "column", lg: "column" }, alignItems: { xs: "center", sm: "center", md: "start", lg: "start" } }} display="flex" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.image")}
                </Typography>
              </Box>
              <Box flex={1.2}>{produit?.product_image?.product_image ? <img style={{ width: "auto", height: "auto", aspectRatio: "1/1" }} src={produit?.product_image?.product_image ? produit?.product_image?.product_image : null} alt="product" /> : "-"}</Box>
            </Box>
          </Box>

          <Box>
            <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
              <Box flex={1}>
                <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                  {t("forms.labels.bd")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="text_md_black">{produit?.product_db_name ? produit?.product_db_name : "-"}</Typography>{" "}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default ProduitDetails;
