export const columns_a_traiter_produit_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DÉSIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "20%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "EMPLACEMENT",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "location",
  },
  {
    name: "JOURS RESTANTS",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "days_until_expiration",
  },
];
export const columns_a_traiter_produit_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DESIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMAT",
    width: "20%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "LOCATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "location",
  },
  {
    name: "REMAINING DAYS",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "days_until_expiration",
  },
];
export const columns_a_traiter_produit_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "",
    width: "30%",
    align: "left",
    type: "file",
    original_name: "image",
  },
  {
    name: "DESIGNACIÓN",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMATO",
    width: "20%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "location",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "location",
  },
  {
    name: "DÍAS RESTANTES",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "days_until_expiration",
  },
];
