import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLogin } from "../../../hooks/useLogin";
import { useTranslation } from "react-i18next";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
import { action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";
import { colors } from "../../../theme/colors";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useNavigate, useParams } from "react-router-dom";

const DetailsAction = () => {
  const lang = localStorage?.getItem("i18nextLng");
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const access_token = user?.access;
  const [data, setData] = useState(null);
  const refresh_token = user?.refresh;
  const { id } = useParams();
  const { t } = useTranslation();
  const [succes, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [modification, setModification] = useState({ state: false, data: null });
  const [isSubmitingChangeDate, setIsSubmitingChangeDate] = useState(false);
  let action_types_list = lang === "fr" ? action_types_list_fr : lang === "en" ? action_types_list_en : lang === "es" ? action_types_list_es : {};
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const { refreshToken } = useLogin();
  const [initialValuesGestionInvendu, setinitialValuesGestionInvendu] = useState({
    prix: "",
    prix_red: "",
    action: { name: "", des: "" },
    quantity: "",
  });
  useEffect(() => {
    Api.get(Urls.getActionByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setinitialValuesGestionInvendu({
          prix: res?.data?.price,
          prix_red: res?.data?.price_reduction,
          action: action_types_list?.filter((action) => action?.des === res?.data?.action)[0],
          quantity: res?.data?.quantity,
        });
        setData(res?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setRefresh(!refresh);
          });
        }
      });
  }, [refresh]);
  useEffect(() => {
    if (modification?.data) {
      UpdateAction(modification?.data);
    }
  }, [modification]);
  const UpdateAction = (values) => {
    Api.patch(
      Urls.updateActionByID(id),
      {
        ...(values?.prix && { price: values?.prix }),
        ...(values?.prix_red && { price_reduction: values?.prix_red }),
        ...(values?.action?.des && { action: values?.action?.des }),
        ...(values?.quantity && { quantity: values?.quantity }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setRefresh(!refresh);
        setSuccess(t("forms.success.update"));

        setTimeout(() => {
          setSuccess("");
          window.location.reload();
        }, 2000);
        setIsSubmitingChangeDate(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setModification({ state: !modification?.state, data: values });
          });
        } else {
          setIsSubmitingChangeDate(false);
          setError(err?.response?.data?.details);
        }
      });
  };
  return (
    <Box>
      <Box sx={{ margin: "40px 0px" }} maxWidth={"600px"}>
        <Formik
          initialValues={initialValuesGestionInvendu}
          enableReinitialize
          onSubmit={(values) => {
            UpdateAction(values);
          }}
        >
          {({ errors, values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" gap="20px">
                <Box textAlign="center" display="flex" gap="10px">
                  <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.quantity")}</Typography>{" "}
                  </Box>
                  <Box sx={{ flex: "3" }}>
                    <Field name="quantity" type="number" placeholder={t("forms.labels.quantity")} as={TextField} />
                    <ErrorMessage name="quantity" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>{" "}
                <Box textAlign="center" display="flex" gap="10px">
                  <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.action")}</Typography>{" "}
                  </Box>
                  <Box sx={{ flex: "3" }}>
                    <Autocomplete
                      options={action_types_list?.filter((action) => action?.des !== "CD" && action.des !== "RE")}
                      value={values.action}
                      getOptionLabel={(e) => e?.name}
                      isOptionEqualToValue={(option, value) => option?.des === value?.des}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue("action", value);
                        } else setFieldValue("action", { name: "", des: "" });
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" placeholder={action_types_list[0].name} />}
                    />
                    <ErrorMessage name="action.des" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
                <Box textAlign="center" display="flex" gap="10px">
                  <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.prix")}</Typography>{" "}
                  </Box>
                  <Box sx={{ flex: "3" }}>
                    <Field name="prix" type="number" placeholder={t("forms.labels.prix")} as={TextField} />
                    <ErrorMessage name="prix" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>{" "}
                <Box textAlign="center" display="flex" gap="10px">
                  <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.prix_red")}</Typography>{" "}
                  </Box>
                  <Box sx={{ flex: "3" }}>
                    <Field name="prix_red" type="number" placeholder={t("forms.labels.prix_red")} as={TextField} />
                    <ErrorMessage name="prix_red" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
                {data?.action === "RE" && (
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("navs_marchand.rappel")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{data?.recall_date}</Typography>{" "}
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>UPC</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3", cursor: "pointer" }} onClick={() => navigate("/marchand/gestion-des-produit/liste/" + data?.product?.id)}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{data?.product?.upc}</Typography>{" "}
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.designation")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{data?.product?.designation}</Typography>{" "}
                      </Box>
                    </Box>
                    <Box textAlign="center" display="flex" gap="10px">
                      <Box sx={{ flex: "1", display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{t("forms.labels.format")}</Typography>{" "}
                      </Box>
                      <Box sx={{ flex: "3" }}>
                        <Typography sx={{ fontSize: "15px", fontWeight: "600", color: "black" }}>{data?.product?.format}</Typography>{" "}
                      </Box>
                    </Box>
                  </Box>
                )}
                <Box display="flex" justifyContent="center" gap="20px">
                  <Button disabled={isSubmitingChangeDate} type="submit" variant="primary">
                    {t("forms.submit.save")}
                  </Button>
                </Box>
                {succes && <Alert severity="success">{succes}</Alert>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default DetailsAction;
