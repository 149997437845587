import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, List, ListItem, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { colors } from "../../../theme/colors";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useLogin } from "../../../hooks/useLogin";
import { useEffect, useState } from "react";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import Loading from "../../../components/ui/Loading";
const DbDuplication = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [limit, setLimit] = useState({ state: true, number: 10 });
  const [limitBanners, setLimitBanners] = useState({ state: true, number: 10 });
  const [banners, setBanners] = useState([]);
  const [modification, setModification] = useState({ type: "refresh", state: false, count: 0, data: null });
  const [success, setSuccess] = useState("");
  const [loadingDup, setLoadingDup] = useState(false);
  const [error, setError] = useState("");
  const lang = localStorage?.getItem("i18nextLng");
  const [isMaster, setIsMaster] = useState(false);
  const { refreshToken } = useLogin();
  const [loading, setLoading] = useState({
    loadingBanners: true,
    loadingDbs: true,
  });
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const initial_values = {
    bd: { name: "", id: "" },
    name: "",
    is_master: false,
    franchise: { name: "", id: "" },
  };
  const validationSchema = yup.object({
    bd: yup.object({
      id: yup?.string().required(t("validation_required")),
    }),
    name: yup?.string().required(t("validation_required")),
    is_master: yup?.boolean().required(t("validation_required")),
    franchise: yup.object({
      id: yup?.string().required(t("validation_required")),
    }),
  });
  useEffect(() => {
    Api.get(Urls?.GET_BDS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit?.number,
      },
    })

      .then((res) => {
        setData((data) => {
          return res?.data?.results;
        });
        if (limit?.state !== false) {
          setLimit({ state: false, number: res?.data?.count });
        } else {
          setLoading({
            loadingDbs: false,
            loadingBanners: true,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "refresh-db", state: !modification?.state, count: modification.count + 1 };
            setModification(newState);
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  }, [modification, limit]);
  useEffect(() => {
    if (!loading?.loadingDbs) {
      Api.get(Urls?.GET_BANNIERES, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: limitBanners?.number,
          has_master_db: !isMaster,
        },
      })

        .then((res) => {
          setBanners((data) => {
            return res?.data?.results;
          });
          if (limitBanners?.state !== false) {
            setLimitBanners({ state: false, number: res?.data?.count });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "refresh-banners", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setError(err?.response?.data?.details);
          }
        });
    }
  }, [modification, limitBanners, isMaster, loading]);
  useEffect(() => {
    if (modification?.type === "submit") {
      handleSubmit(modification?.data);
    }
  }, [modification]);
  //HANDLE SBUMIT CREATE MANAGER
  const handleSubmit = (values) => {
    setError(null);
    setLoadingDup(true);
    Api.post(
      Urls.DUPLICATE_DB,
      {
        name: values?.name,
        product_db_id: values?.bd?.id,
        franchise_id: values?.franchise?.id,
        is_master: values?.is_master,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.add"));
        setLoadingDup(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
        }
        setLoadingDup(false);
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      name: error?.name,
      product_db_id: error?.product_db_id,
    });
  }
  return (
    <Box>
      <Formik initialValues={initial_values} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ errors, setFieldValue, values }) => (
          <Form>
            <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.db_model")}
                    </Typography>
                  </Box>
                  <Box>
                    <Autocomplete
                      sx={{ minWidth: "262px" }}
                      value={values.bd}
                      options={data}
                      getOptionLabel={(e) => e?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue("bd", value);
                        } else {
                          setFieldValue("bd", null);
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.db_model")} />}
                    />
                    <ErrorMessage name="bd.id" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.new_db")}
                    </Typography>
                  </Box>
                  <Box>
                    <Field name="name" type="input" placeholder={t("forms.labels.new_db")} as={TextField} />
                    <ErrorMessage name="name" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                <Box flex={1}>
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.bd")} MASTER
                  </Typography>
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={values?.is_master}
                        value={values?.is_master}
                        onChange={(e) => {
                          setFieldValue("is_master", e.target.checked);
                          setIsMaster(e.target.checked);
                          setFieldValue("franchise", { name: "", id: "" });
                        }}
                      />
                    }
                    label="IS MASTER"
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                  <Box flex={1}>
                    <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                      {t("forms.labels.banniere")}
                    </Typography>
                  </Box>
                  <Box>
                    <Autocomplete
                      sx={{ minWidth: "262px" }}
                      value={values.franchise}
                      options={banners}
                      getOptionLabel={(e) => e?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue("franchise", value);
                        } else {
                          setFieldValue("franchise", null);
                        }
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.banniere")} />}
                    />
                    <ErrorMessage name="franchise.id" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                </Box>
              </Box>

              {success && <Alert severity="success">{success}</Alert>}
              {error && (
                <Alert severity="error">
                  <Box>
                    <List>
                      {error?.name && (
                        <ListItem>
                          {t("forms.labels.new_db")} : {error?.name}
                        </ListItem>
                      )}
                      {error?.product_db_id && (
                        <ListItem>
                          {t("forms.labels.db_model")} : {error?.product_db_id}
                        </ListItem>
                      )}
                    </List>
                  </Box>
                </Alert>
              )}
              <Box>
                <Button disabled={loadingDup} type="submit" variant="primary">
                  {t("forms.submit.save")}{" "}
                  {loadingDup ? (
                    <Box marginLeft="10px">
                      <Loading color="white" size={20} />
                    </Box>
                  ) : null}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default DbDuplication;
