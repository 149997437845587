import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { colors } from "../../../theme/colors";
import logo_fr from "../../../assets/exppro-logo-600px-francais-white.svg";
import logo_en from "../../../assets/exppro-logo-600px-anglais-white.svg";
import logo_es from "../../../assets/exppro-logo-600px-spanish-white.svg";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/ui/LanguageSelector";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
const PasswordRecovery = () => {
  const timer = JSON.stringify(localStorage?.getItem("timer"));
  const [isLoading, setIsLoading] = useState(false);
  const [step1validation, setStep1Validation] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [isRunning, setIsRunning] = useState(localStorage?.getItem("timer") ? true : false);
  const [email, setEmail] = useState();
  const { t } = useTranslation();
  function getSecondsDifference(date1, date2) {
    const differenceInMilliseconds = date2 - date1;
    const differenceInSeconds = differenceInMilliseconds / 1000;
    return differenceInSeconds;
  }
  //timer
  useEffect(() => {
    let interval;

    if (isRunning && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning, timeRemaining]);
  useEffect(() => {
    if (step1validation) {
      setIsRunning(true);
      setTimeRemaining(60);
      localStorage.setItem("timer", new Date());
    }
  }, [step1validation]);
  useEffect(() => {
    if (timer) {
      console.log(new Date(), new Date(moment(timer).format()));
      let difference = getSecondsDifference(new Date(moment(timer).format()), new Date());
      setTimeRemaining(60 - difference);
    }
  }, []);
  const initialValues = {
    email: "",
  };
  const validationSchema = yup?.object({
    email: yup.string().email(t("email_validation")).required(t("validation_required")),
  });
  const initialValuesUpdatePass = {
    nouveau_motdepasse: "",
    confirmer_motdepasse: "",
  };
  //validation schema
  const validationSchemaUpdatePass = yup.object({
    code: yup.string().required(t("validation_required")),
    nouveau_motdepasse: yup.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
    confirmer_motdepasse: yup
      .string()
      .required(t("validation_required"))
      .oneOf([yup.ref("nouveau_motdepasse"), null], t("password_match_validation")),
  });
  const submit = (values) => {
    setErrorSubmit("");
    setIsLoading(true);
    Api.post(
      Urls?.PASSWORD_RECOVERY_STEP1,
      {
        email: values?.email,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        setStep1Validation(true);
        setEmail(values?.email);
        toast.success(t("succes_code"));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 400) setErrorSubmit(err?.response?.data?.email[0]);
        else setErrorSubmit(t("error_500"));
      });
  };
  const newPassRequest = () => {
    setIsLoading(true);
    Api.post(
      Urls?.PASSWORD_RECOVERY_STEP1,
      {
        email: email,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        setIsLoading(false);
        toast.success(t("succes_code"));
        setIsRunning(true);
        setTimeRemaining(60);
        localStorage.setItem("timer", new Date());
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 400) setErrorSubmit(err?.response?.data?.email[0]);
        else setErrorSubmit(t("error_500"));
      });
  };
  //validate new pass
  const validateNewPass = (values) => {
    setIsLoading(true);
    Api.post(
      Urls?.PASSWORD_RECOVERY_STEP2,
      {
        token: values?.code,
        password: values?.nouveau_motdepasse,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        setIsLoading(false);
        toast?.success("OK");
        navigate("/login");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.status === 404) setErrorSubmit(t("error_recovery"));
        else setErrorSubmit(t("error_500"));
      });
  };
  return (
    <Box width="100%" minHeight="100vh" sx={{ background: colors?.gradient180 }}>
      <ToastContainer />
      <Box width="100%" display="flex" justifyContent="end">
        <LanguageSelector />
      </Box>
      <Box width="100%">
        <Box maxWidth="600px" display="flex" justifyContent="center" margin="100px auto" marginBottom="0px">
          {t("logo") === "logo_fr" && <img src={logo_fr} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
          {t("logo") === "logo_en" && <img src={logo_en} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
          {t("logo") === "logo_es" && <img src={logo_es} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box height="fit-content">
          <Box sx={{ textAlign: "center", marginBottom: "32px" }}>
            <Typography variant="text_lg_white">{t("password_recovery")}</Typography>
          </Box>
          {!step1validation && (
            <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
              {({ errors }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="32px">
                    <Box display="flex" alignItems="top" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                      <Box flex={1}>
                        <Typography variant="text_lg_white">{t("user_email")}</Typography>
                      </Box>
                      <Box width="330px">
                        <Field type="input" name="email" placeholder={t("user_email")} as={TextField} fullWidth autoComplete="off" />
                        <ErrorMessage name="email" component={FormikErrorText} />
                      </Box>
                    </Box>
                    {isRunning && timeRemaining > 0 && (
                      <Box display="flex" justifyContent="end">
                        <Typography variant="text_md_white">
                          {t("timer_recovery")} {Math.floor(timeRemaining)}s
                        </Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center">
                      <Button disabled={isLoading || (timeRemaining > 0 ? true : false)} variant="white" type="submit">
                        {t("submit")}
                      </Button>
                    </Box>
                    {errorSubmit && (
                      <Box display="flex" justifyContent="center">
                        <Alert sx={{ marginTop: "1rem" }} severity="error">
                          {errorSubmit}
                        </Alert>
                      </Box>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
          {step1validation && (
            <Formik onSubmit={validateNewPass} initialValues={initialValuesUpdatePass} validationSchema={validationSchemaUpdatePass}>
              {({ errors }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="32px">
                    <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                      <Box flex={1}>
                        <Typography variant="text_lg_white">{t("token")}</Typography>
                      </Box>
                      <Box>
                        <Field type="input" name="code" placeholder={t("token")} as={TextField} autoComplete="off" />
                        <ErrorMessage name="code" component={FormikErrorText} />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                      <Box flex={1}>
                        <Typography variant="text_lg_white">{t("password")}</Typography>
                      </Box>
                      <Box>
                        <Field type="password" name="nouveau_motdepasse" placeholder={t("password")} as={TextField} autoComplete="off" />
                        <ErrorMessage name="nouveau_motdepasse" component={FormikErrorText} />
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                      <Box flex={1}>
                        <Typography variant="text_lg_white">{t("confirm_password")}</Typography>
                      </Box>
                      <Box>
                        <Field type="password" name="confirmer_motdepasse" placeholder={t("confirm_password")} as={TextField} autoComplete="off" />
                        <ErrorMessage name="confirmer_motdepasse" component={FormikErrorText} />
                      </Box>
                    </Box>
                    {isRunning && timeRemaining > 0 && (
                      <Box display="flex" justifyContent="end">
                        <Typography variant="text_md_white">
                          {t("timer_recovery")} {timeRemaining}s
                        </Typography>
                      </Box>
                    )}
                    {timeRemaining === 0 && (
                      <Box display="flex" justifyContent="end">
                        <Typography sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={newPassRequest} variant="text_md_white">
                          {t("new_code")}
                        </Typography>
                      </Box>
                    )}
                    <Box display="flex" justifyContent="center">
                      <Button disabled={isLoading} variant="white" type="submit">
                        {t("submit")}
                      </Button>
                    </Box>
                    {errorSubmit && (
                      <Box display="flex" justifyContent="center">
                        <Alert sx={{ marginTop: "1rem" }} severity="error">
                          {errorSubmit}
                        </Alert>
                      </Box>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default PasswordRecovery;
