import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import Api from "../api/Api";
import { useTranslation } from "react-i18next";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const [errorLogin, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const { dispatch } = useAuthContext();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const login = async (username, password) => {
    setIsLoading(true);
    setError(null);
    Api.post(
      Urls.AUTH,
      { username, password },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.authenticatedUser?.role === 4) {
            Api.get(Urls?.GET_FRANCHISE_ALERT_LAUNCHER, {
              headers: { "Content-type": "application/json", Authorization: `Bearer ${response?.data?.access}` },
            }).then((res) => {
              localStorage.setItem("franchise_info", JSON.stringify(res?.data));
            });
          }
          if (response?.data?.authenticatedUser?.role === 2) {
            Api.get(Urls?.GetFranchiseMarchand, {
              headers: { "Content-type": "application/json", Authorization: `Bearer ${response?.data?.access}` },
            }).then((res) => {
              localStorage.setItem("franchise_info", JSON.stringify(res?.data));
            });
          }
          if (response?.data?.authenticatedUser?.role === 3) {
            Api.get(Urls?.GET_FRANCHISE_EMPLOYEE, {
              headers: { "Content-type": "application/json", Authorization: `Bearer ${response?.data?.access}` },
            }).then((res) => {
              localStorage.setItem("franchise_info", JSON.stringify(res?.data));
            });
            Api.get(Urls?.GetEmptyShelf, {
              headers: { "Content-type": "application/json", Authorization: `Bearer ${response?.data?.access}` },
              params: {
                treated: false,
              },
            }).then((res) => {
              if (res?.data?.results?.length > 0) {
                navigate("/employee/gestion-des-tablettes/liste", { state: { treate: "not_traated" } });
              } else {
                Api.get(Urls?.GET_PRODUITS_A_TRAITER, {
                  headers: { "Content-type": "application/json", Authorization: `Bearer ${response?.data?.access}` },
                  params: {
                    treated: false,
                  },
                }).then((res) => {
                  if (res?.data?.results?.length > 0) {
                    navigate("/employee/gestion-des-traiter/liste");
                  } else {
                    navigate("/employee/gestion-des-suivis/liste");
                  }
                });
              }
            });
          }
          // save the user to local storage
          localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });
          // update loading state
          setIsLoading(false);
          //navigate to dashboard
          navigate(response?.data?.authenticatedUser?.role === 1 ? "/admin/gestion-des-marchands/liste" : response?.data?.authenticatedUser?.role === 2 ? "/marchand/gestion-des-produit/liste" : "");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error?.response?.status === "400") setError(t("error_login"));
        else setError(t("error_500"));
      });
  };
  const refreshToken = (refresh) => {
    return new Promise((resolve, reject) => {
      Api.post(Urls.REFRESH_TOKEN, {
        refresh: refresh,
      })
        .then((res) => {
          let { access, refresh, ...data } = user;
          data = { ...data, access: res?.data?.access, refresh: res?.data?.refresh };
          let new_user = { data };
          localStorage.setItem("user", JSON.stringify(new_user?.data));
          dispatch({ type: "LOGIN", payload: new_user?.data });
          resolve(new_user);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            localStorage.removeItem("user");
            dispatch({ type: "REFRESH", payload: null });
          }
        });
    });
  };

  return { login, isLoading, errorLogin, refreshToken };
};
