import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import { frFR, esES, enUS } from "@mui/material/locale";
//mui custom theme
const lang = localStorage?.getItem("i18nextLng");
let componentLang = lang === "en" ? enUS : lang === "fr" ? frFR : esES;
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.main,
        secondary: colors.secondary,
        white: "#FFFFF",
      },
      neutral: {
        main: "#FFFFF",
      },
    },
    typography: {
      fontFamily: ["Public sans"],
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            minHeight: "10px",
            minWidth: "10px",
          },
        },
        variants: [
          {
            props: {
              variant: "primary",
            },
            style: {
              background: colors?.gradient90,
              color: "white",
              fontSize: "18px",

              lineHeight: "110%",
              padding: "5px 20px",
            },
          },
          {
            props: {
              variant: "primary_selected",
            },
            style: {
              background: colors?.gradient90,
              color: "white",
              fontSize: "14px",
              border: "2px solid black",
            },
          },
          {
            props: {
              variant: "primary_not_selected",
            },
            style: {
              background: "white",
              color: "black",
              fontSize: "14px",
              border: "2px solid black",
            },
          },
          {
            props: {
              variant: "white",
            },
            style: {
              background: "white",
              color: "black",
              fontSize: "25px",
              fontWeight: "600",
              padding: "0px 20px",
              border: "1px solid black",
              borderRadius: "0px",
            },
          },
        ],
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: "100%",
            color: "white",

            "& .MuiInputBase-root": {
              minHeight: "2/px",
              padding: "0px 10px",
              width: "100%",
            },
            "& .MuiAutocomplete-input": {
              minHeight: "28px",

              padding: "0px 10px !important",
              width: "100%",
            },
            "& .css-gqvsnj-MuiAutocomplete-root": {
              minHeight: "28px",

              padding: "0px 10px",
              width: "100%",
            },
            "& .css-wq7oss-MuiAutocomplete-root": {
              minHeight: "28px",

              padding: "0px 10px",
              width: "100%",
            },
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            padding: "0px",
            margin: "0px",
            "& .MuiToolbar-root": {
              padding: "0px",
              margin: "0px",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            lineHeight: "100% !important",
          },
        },
        variants: [
          {
            props: {
              variant: "text_sm_white",
            },
            style: {
              fontSize: "13px",
              color: "white",
            },
          },
          {
            props: {
              variant: "text_sm_black",
            },
            style: {
              fontSize: "13px",
              color: "black",
            },
          },
          {
            props: {
              variant: "text_md_white",
            },
            style: {
              fontSize: "18px",
              color: "white",
            },
          },
          {
            props: {
              variant: "text_md_black",
            },
            style: {
              fontSize: "18px",
              color: "black",
            },
          },
          {
            props: {
              variant: "text_lg_white",
            },
            style: {
              fontSize: "32px",
              color: "white",
              fontWeight: "700",
              letterSpacing: "1px",
            },
          },
          {
            props: {
              variant: "text_lg_black",
            },
            style: {
              fontSize: "32px",
              color: "black",
              fontWeight: "600",
              letterSpacing: "1px",
            },
          },
          {
            props: {
              variant: "text_lg_red",
            },
            style: {
              fontSize: "32px",
              color: "#EA1D21",
              fontWeight: "600",
              letterSpacing: "1px",
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100%",
            color: "black",
            backgroundColor: "white",
            borderRadius: "6px",
            border: "1px solid black",
            "& .MuiButtonBase-root": {
              minHeight: "10px",
              minWidth: "10px",
            },
            "& .MuiInputBase-root": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-input": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-inputMultiline": {
              borderRadius: "6px",
              fontSize: "18px",
              lineHeight: "22.4px",
              fontWeight: "600",
              padding: "10px 10px",
            },
            "& .MuiOutlinedInput-root": {
              minHeight: "38px",
              borderRadius: "6px",
              fontSize: "18px",
              lineHeight: "22.4px",
              fontWeight: "600",
              padding: "0px 10px",

              "& fieldset": {
                borderColor: "#DEDFE4",
                borderRadius: "6px",
                fontSize: "18px",
                lineHeight: "22.4px",
                fontWeight: "600",
                padding: "0px 10px",
              },
              "&:placeholder fieldset": {
                color: "#DEDFE4",
              },
              "&:hover fieldset": {
                border: `2px solid black`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid black`,
              },
            },
          },
        },
      },
    },
  },
  componentLang
);
