import { Alert, Autocomplete, Avatar, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import imagePlaceholder from "../../../assets/placeholder-4-300x200.png";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { CloseOutlined, SelectAll, UploadFile } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { action_types_en, action_types_es, action_types_fr, action_types_list_en, action_types_list_es, action_types_list_fr } from "../../../assets/data/ActionTypes";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";
const UpdateProduit = () => {
  const [error, setError] = useState("");
  const { refreshToken } = useLogin();
  const { user } = useAuthContext();
  const [selectedType, setSelectedType] = useState();
  const refresh_token = user?.refresh;
  const lang = localStorage?.getItem("i18nextLng");
  const { id } = useParams();
  const [success, setSuccess] = useState("");
  const [errorDBS, setErrorDBS] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [faillureAdding, setFaillureAdding] = useState([]);
  const [produit, setProduit] = useState({});
  const [successAdding, setSuccessAdding] = useState([]);
  const [modification, setModification] = useState(false);
  let action_types = lang === "fr" ? action_types_fr : lang === "en" ? action_types_en : lang === "es" ? action_types_es : {};

  const [departement, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const access_token = user?.access;
  const buttons = lang === "fr" ? action_types_list_fr : lang === "en" ? action_types_list_en : action_types_list_es;
  const [loading, setLoading] = useState({
    loadingDeps: true,
    loadingProd: true,
  });
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;
  const [noDispo, setNoDispo] = useState(false);
  const [loadingG, setLoadingG] = useState(true);
  const { t } = useTranslation();
  const [initial_values, setInitialValues] = useState({
    upc: "",
    format: "",
    designation: "",
    category: categories[0],
    category_value: categories[0]?.value,
    dep: null,
    sub_dep: null,
    localisation: "",
    empty_shelf: false,
    prix: "",
    no_date: false,
    prix_red: "",
    expiration_date: "",
    fournisseur: "",
    note: "",
    use_admin: false,
    image: "",
    alert: 30,
    active: false,
  });
  useEffect(() => {
    if (!loading.loadingDeps) {
      Api.get(Urls?.GetProduitIDManager(id), {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          console.log(res);
          setProduit(res?.data);
          const cat_val = categories?.filter((cat) => cat?.value === res?.data?.category)[0]?.value;
          setInitialValues({
            upc: res?.data?.upc,
            format: res?.data?.format,
            designation: res?.data?.designation,
            dep: res?.data?.department_number ? { number: res?.data?.department_number, name: res?.data?.department_name } : null,
            sub_dep: res?.data?.sub_department_number ? { number: res?.data?.sub_department_number, name: res?.data?.sub_department_name } : null,
            localisation: res?.data?.location,
            empty_shelf: res?.data?.empty_shelf,
            category: categories?.filter((cat) => cat?.value === res?.data?.category)[0],
            category_value: cat_val,
            no_date: res?.data?.expiration_date_not_available,
            prix: res?.data?.price,
            prix_red: res?.data?.price_reduction,
            fournisseur: res?.data?.supplier,
            note: res?.data?.note,
            expiration_date: res?.data?.expiration_date,
            image: "",
            use_admin: res?.data?.use_admin_image,
            alert: res.data?.alert_in_days,
            active: res?.data?.active,
          });
          setNoDispo(res?.data?.expiration_date_not_available || res?.data?.empty_shelf);
          setLoadingG(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              console.log(res, "new user");
              setModification(!modification);
            });
          } else {
          }
        });
    }
  }, [modification, loading]);
  //validation schema
  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };
  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };
  const validationSchema = yup.object({
    upc: yup?.string().required(t("validation_required")),
    designation: yup?.string().required(t("validation_required")),
    category_value: yup?.string().nonNullable(t("validation_required")).required(t("validation_required")),
  });
  const validationSchemaImage = yup.object({
    image: yup
      .mixed()
      .nullable()
      .test("fileType", "Invalid file type", (file) => (!file ? true : validateFileType(file)))
      .test("fileSize", "File size exceeds 5MB", (file) => (!file ? true : validateFileSize(file))),
  });
  useEffect(() => {
    Api.get(Urls?.GET_FRANCHISE_INFOS_MANAGER, {
      headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDepartments(res?.data?.departments);
        setSubDepartments(res?.data?.sub_departments);
        setLoading({
          loadingDeps: false,
          loadingProd: true,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setUpdate(!update);
          });
        } else {
          setError({ details: err?.response?.data?.detail });
          setLoading({
            loadingDeps: false,
            loadingProd: true,
          });
        }
      });
  }, [update]);

  useEffect(() => {
    if (modification?.type === "submit" && modification?.data) {
      let data = modification?.data;
      handleSubmit(data);
    }
  }, [modification]);
  useEffect(() => {
    if (modification?.type === "submit-image" && modification?.data) {
      let data = modification?.data;
      handleSubmitImage(data);
    }
  }, [modification]);
  const formikRef = useRef();
  const [history, setHistory] = useState([]);
  const [data, setData] = useState({});
  const [updateSelectedType, setUpdateSelectedType] = useState(false);
  useEffect(() => {
    Api.get(Urls.GetAtionsForAcertainProduct(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setHistory(res?.data?.results);
        setData(res);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdateSelectedType(!updateSelectedType);
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  }, [updateSelectedType]);
  const [updatePag, setUpdatePag] = useState(false);
  useEffect(() => {
    handlePagination(data?.next);
  }, [updatePag]);
  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData(res);
        setHistory((history) => history.concat(res?.results));
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdatePag(!updatePag);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  };
  //HANDLE SBUMIT CREATE PRODUCT

  const handleSubmit = (values) => {
    setErrorDBS("");
    setError(null);
    setLoadingSubmit(true);
    setFaillureAdding([]);
    Api.patch(
      Urls.UpdateProductManager(id),
      {
        upc: values?.upc,
        format: values?.format,
        category: values?.category_value,
        active: values?.active,
        price: values?.prix,
        price_reduction: values?.prix_red,
        expiration_date: values?.expiration_date ? values?.expiration_date : "",
        empty_shelf: values?.empty_shelf,
        alert_in_days: values?.alert,
        location: values?.localisation,
        designation: values?.designation,
        use_admin_image: values?.use_admin,
        private_product_image: values?.image,
      },
      {
        headers: { "Content-Type": "multipart/form-data", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        console.log(res, "res");
        setLoadingSubmit(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        //window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
          setLoadingSubmit(false);
        }
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      upc: error?.upc,
      format: error?.format,
      department: error?.department,
      sub_department: error?.sub_department,
      price: error?.price,
      price_reduction: error?.price_reduction,
      alert_in_days: error?.alert_in_days,
      supplier: error?.supplier,
      note: error?.note,
      product_db_id: error?.product_db_id,
      location: error?.location,
      designation: error?.designation,
      product_image: error?.product_image,
    });
  }

  const FileUploadTextField = ({ field, form, ...props }) => {
    const handleFileChange = (event) => {
      form.setFieldValue(field.name, event.currentTarget.files[0]);
    };

    return (
      <TextField
        {...props}
        id={field.name}
        type="text"
        value={field?.value ? field.value.name : ""}
        InputProps={{
          startAdornment: (
            <label htmlFor={field.name}>
              <input type="file" id={field?.name} name={field?.name} style={{ display: "none" }} onChange={handleFileChange} accept="image/*" />
              <Button sx={{ borderRadius: "0px", width: "50px", marginLeft: "-10px", borderRight: "1px solid #dedfe4" }} component="span" type="file">
                <UploadFile />
              </Button>
            </label>
          ),
        }}
      />
    );
  };
  const [progressFile, setProgressFile] = useState(0);
  var config = {
    headers: { "Content-Type": "multipart/form-data", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressFile(percentCompleted);
    },
  };
  const handleSubmitImage = (values) => {
    setLoadingSubmit(true);
    console.log(values);
    Api.post(
      Urls.updateimageproduct(id),
      {
        private_product_image: values.image,
      },
      config
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        console.log(res, "res");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setProgressFile(0);
        setLoadingSubmit(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit-image", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
          setLoadingSubmit(false);
          setProgressFile(0);
        }
      });
  };

  return (
    <Box>
      {loadingG && <Loading />}
      {!loadingG && (
        <Box display="flex" gap="24px" flexWrap="wrap">
          <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={handleSubmit}>
            {({ errors, values, setFieldValue }) => (
              <Form>
                <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          UPC / PLU
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="upc" type="input" placeholder="UPC" as={TextField} />
                        <ErrorMessage name="upc" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.designation")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="designation" type="input" placeholder={t("forms.labels.designation")} as={TextField} />
                        <ErrorMessage name="designation" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.format")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="format" type="input" placeholder={t("forms.labels.format")} as={TextField} />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.localisation")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="localisation" placeholder={t("forms.labels.localisation")} as={TextField} />
                        <ErrorMessage name="localisation" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.category")}
                      </Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        sx={{ minWidth: "262px", maxWidth: "263px" }}
                        options={categories}
                        disableClearable
                        value={values.category}
                        getOptionLabel={(e) => e?.name}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("category_value", value?.value);
                            setFieldValue("category", value);
                          } else {
                            setFieldValue("category", null);
                            setFieldValue("category_value", "");
                          }
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
                      />
                    </Box>
                    <ErrorMessage name="category_value" component={FormikErrorText} color={colors.main} width="250px" />
                  </Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.expiration_date")}
                      </Typography>
                    </Box>
                    <Box flex={1.2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="yyyy-MM-dd"
                          value={values.expiration_date}
                          minDate={new Date()}
                          disabled={noDispo}
                          InputProps={{ sx: { "& .MuiSvgIcon-root": { color: colors?.main, height: "30px", width: "30px" } } }}
                          onChange={(value) => {
                            setFieldValue("expiration_date", moment(value).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      <ErrorMessage name="expiration_date" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                  <Formik
                    validationSchema={validationSchemaImage}
                    initialValues={{
                      image: "",
                    }}
                    innerRef={formikRef}
                    onSubmit={handleSubmitImage}
                  >
                    {({ errors }) => (
                      <Form>
                        <Box maxWidth="500px" display="flex" alignItems={"end"} gap="10px" flexDirection="row">
                          <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                            <Box flex={1}>
                              <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                                {t("forms.labels.image")}
                              </Typography>
                            </Box>
                            <Box flex={1.6}>
                              <Field autoComplete="off" name="image" placeholder="Choose a file" id="icon" component={FileUploadTextField} fullWidth />
                              <ErrorMessage name="image" component={FormikErrorText} color={colors.main} width="250px" />
                            </Box>
                          </Box>
                          <Box display="flex" gap="10px" alignItems="center">
                            <Button
                              disabled={loadingSubmit}
                              onClick={() => {
                                console.log(formikRef);
                                formikRef?.current?.handleSubmit();
                              }}
                              type="button"
                              variant="primary"
                            >
                              {t("forms.submit.save")}
                            </Button>
                            {progressFile !== 0 && (
                              <Box display="flex" alignItems="center" gap="5px">
                                <Loading color={colors?.main} />
                                <Typography color={colors?.main}>{progressFile}%</Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.prix")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="prix" placeholder={t("forms.labels.prix")} as={TextField} />
                        <ErrorMessage name="prix" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.prix_red")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="prix_red" type="input" placeholder={t("forms.labels.prix_red")} as={TextField} />
                        <ErrorMessage name="prix_red" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.alert")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field type="number" name="alert" placeholder={t("forms.labels.alert")} as={TextField} />
                        <ErrorMessage name="alert" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>

                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={values?.active}
                        value={values?.active}
                        onChange={(e) => {
                          setFieldValue("active", e.target?.checked);
                        }}
                      />
                    }
                    label="ACTIVE"
                  />
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={values?.no_date}
                        value={values?.no_date}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue("expiration_date", null);
                            setFieldValue("empty_shelf", false);
                          }
                          setNoDispo(e.target.checked);
                          setFieldValue("no_date", e.target.checked);
                        }}
                      />
                    }
                    label={t("forms.labels.date_non_disponible")}
                  />

                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={values?.empty_shelf}
                        value={values?.empty_shelf}
                        onChange={(e) => {
                          setFieldValue("empty_shelf", e.target.checked);

                          if (e.target.checked) {
                            setFieldValue("no_date", false);
                          }
                          setNoDispo(e.target.checked);
                          setFieldValue("expiration_date", null);
                        }}
                      />
                    }
                    label={t("forms.labels.tablette_vide")}
                  />
                  <FormControlLabel
                    sx={{
                      fontWeight: "600",
                      color: colors?.main,
                      fontSize: "18px",
                    }}
                    control={
                      <Checkbox
                        checked={values?.use_admin}
                        value={values?.use_admin}
                        onChange={(e) => {
                          setFieldValue("use_admin", e.target?.checked);
                        }}
                      />
                    }
                    label="USE ADMIN PIC"
                  />
                  {success && <Alert severity="success">{success}</Alert>}
                  {error && (
                    <Alert severity="error">
                      <Box>
                        <List>
                          {error?.upc && <ListItem>UPC: {error?.upc}</ListItem>}
                          {error?.format && (
                            <ListItem>
                              {t("forms.labels.format")} : {error?.format}
                            </ListItem>
                          )}
                          {error?.designation && (
                            <ListItem>
                              {t("forms.labels.designation")} : {error?.designation}
                            </ListItem>
                          )}
                          {error?.supplier && (
                            <ListItem>
                              {t("forms.labels.fournisseur")} : {error?.supplier}
                            </ListItem>
                          )}
                          {error?.product_image && <ListItem>IMAGE : {error?.product_image}</ListItem>}
                          {error?.price && (
                            <ListItem>
                              {t("forms.labels.prix")} : {error?.price}
                            </ListItem>
                          )}
                          {error?.price_reduction && (
                            <ListItem>
                              {t("forms.labels.prix_red")} : {error?.price_reduction}
                            </ListItem>
                          )}
                          {error?.product_db_id && (
                            <ListItem>
                              {t("forms.labels.bd")} : {error?.product_db_id}
                            </ListItem>
                          )}
                          {error?.alert_in_days && (
                            <ListItem>
                              {t("forms.labels.alert")} : {error?.alert_in_days}
                            </ListItem>
                          )}
                          {error?.department && (
                            <ListItem>
                              {t("forms.labels.departement")} : {error?.department}
                            </ListItem>
                          )}
                          {error?.sub_department && (
                            <ListItem>
                              {t("forms.labels.sous-dep")} : {error?.sub_department}
                            </ListItem>
                          )}
                        </List>
                      </Box>
                    </Alert>
                  )}

                  <Box display="flex" gap="10px" alignItems="center">
                    <Button disabled={loadingSubmit} type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Box>
            <Avatar sx={{ maxWidth: "400px", minWidth: "300px", height: "auto" }} variant="square" src={produit?.use_admin_image ? (produit?.product_image?.product_image ? process.env.REACT_APP_IMAGE_URL + produit?.product_image?.product_image : imagePlaceholder) : produit.private_product_image ? process.env.REACT_APP_IMAGE_URL + produit.private_product_image : imagePlaceholder} />
          </Box>
        </Box>
      )}

      <Box>
        <Typography sx={{ marginTop: "24px", fontSize: "24px" }}>{t("history")}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="30px" marginTop="24px" padding="16px" borderTop="1px solid black">
        {history?.map((h) => (
          <Box display="flex" gap="24px" flexWrap="wrap">
            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{moment(h?.created_at).format("MMMM DD, yyyy")}</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{h.treated_by}</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{h.note}</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", color: "black" }}>{action_types[`${h.action}`] + " + " + t("forms.labels.update")}</Typography>
          </Box>
        ))}
        {data?.next && (
          <Box display="flex" justifyContent="center">
            <IconButton onClick={() => handlePagination(data?.next)}>+</IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default UpdateProduit;
