import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useLogin } from "../../../hooks/useLogin";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";

function StandBySet() {
  const { user } = useAuthContext();
  const access_token = user.access;
  const refresh_token = user.refresh;
  const { refreshToken } = useLogin();
  const [error, setError] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const [success, setSuccess] = useState("");
  const [update, setUpdate] = useState({
    state: false,
    action: null,
  });
  const { t } = useTranslation();
  const [value, setValue] = useState();
  const getValue = () => {
    Api.get(Urls?.GetStandByValue, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res.data);
        setValue(res?.data?.days_to_add_to_empty_shelf_on_standby);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdate({ state: !update?.state, action: "get" });
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  };
  useEffect(() => {
    getValue();
  }, []);
  useEffect(() => {
    if (update.action === "get") {
      getValue();
    }
    if (update.action === "patch") {
      handleChangeStandByValue();
    }
  }, [update]);
  const handleChangeStandByValue = () => {
    setSubmiting(true);
    setError("");
    setSuccess("");
    Api.patch(
      Urls.UpdateStandByValue,
      {
        days_to_add_to_empty_shelf_on_standby: value,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        setTimeout(() => {
          setSuccess("");
        }, 2000);
        setSubmiting(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            setUpdate({ state: !update?.state, action: "patch" });
          });
        } else {
          setSubmiting(false);
          setError(err?.response?.data?.detail);
        }
      });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Typography sx={{ fontSize: "22px", color: "black", fontWeight: "700" }}>{t("navs_marchand.affichage_tab_delai")}</Typography>
      <Box sx={{ maxWidth: "600px" }}>
        <Box sx={{ display: "flex", gap: "16px", alignItems: "start" }}>
          <TextField value={value} onChange={(e) => setValue(e.target.value)} placeholder="0" type="number" />
          <Box>
            <Button
              onClick={() => {
                handleChangeStandByValue();
              }}
              disabled={value?.length === 0 || submiting}
              variant="primary"
            >
              {t("forms.submit.save")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default StandBySet;
