export const columns_marchand_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANNIÈRE",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "franchise_name",
  },
  {
    name: "ENTREPRISE",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "enterprise_name",
  },
  {
    name: "PRÉNOM RESPONSABLE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_first_name",
  },
  {
    name: "NOM RESPONSABLE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_last_name",
  },
  {
    name: "TÉLÉPHONE",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "phone_number",
  },
];
export const columns_marchand_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANNER",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "franchise_name",
  },
  {
    name: "COMPANY",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "enterprise_name",
  },
  {
    name: "RESPONSIBLE FIRST NAME",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_first_name",
  },
  {
    name: "RESPONSIBLE LAST NAME",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_last_name",
  },
  {
    name: "PHONE",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "phone_number",
  },
];
export const columns_marchand_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANNER",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "franchise_name",
  },
  {
    name: "EMPRESA",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "enterprise_name",
  },
  {
    name: "NOMBRE DEL RESPONSABLE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_first_name",
  },
  {
    name: "APELLIDO DEL RESPONSABLE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "company_manager_last_name",
  },
  {
    name: "TELÉFONO",
    width: "15%",
    align: "left",
    type: "number",
    original_name: "phone_number",
  },
];
