import { Alert, Avatar, Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../../assets/upload-icon.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { toast } from "react-toastify";
import { useLogin } from "../../../hooks/useLogin";
import { colors } from "../../../theme/colors";
import { CloseOutlined } from "@mui/icons-material";

function AddImagesBulk() {
  const [files, setFiles] = useState([]);
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [faillureAdding, setFaillureAdding] = useState([]);
  const [successAdding, setSuccessAdding] = useState([]);
  const [success, setSuccess] = useState("");
  const [updateAssociate, setupdateAssociate] = useState({ state: false, active: false });
  const [error, setError] = useState("");
  const { refreshToken } = useLogin();
  const [update, setUpdate] = useState(false);
  const [drag, setDrag] = useState(false);
  const { t } = useTranslation();
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const DeleteFile = (index) => {
    setFiles(files?.filter((file, i) => i !== index));
  };
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);

  var configsAddFiles = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const addFiles = () => {
    setSuccess("");
    setError("");
    Api.post(
      Urls?.UploadBulkImages,
      {
        product_image: files,
      },
      configsAddFiles
    )
      .then((res) => {
        setProgressUpdatePic(0);
        setSuccess(t("forms.success.add"));
        setSuccessAdding(res?.data?.images_added_list);
        setFaillureAdding(res?.data?.images_not_added_list);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
        setFiles([]);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setUpdate(!update);
          });
        } else {
          setError(err?.response?.data?.detail);
          setProgressUpdatePic(0);
        }
      });
  };
  useEffect(() => {
    if (files?.length > 0) {
      addFiles();
    }
  }, [update]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px">
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {successAdding?.length > 0 && (
          <Box padding="16px" borderRadius="8px" backgroundColor="rgba(144,238,144,0.7)" maxWidth="500px" display="flex" flexDirection="column" gap="10px">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="text_md_black">
                <strong>{t("forms.success.add")}</strong>
              </Typography>
              <IconButton onClick={() => setSuccessAdding([])}>
                <CloseOutlined sx={{ color: "black" }} />
              </IconButton>
            </Box>
            {successAdding?.map((success) => (
              <Box display="flex" gap="8px" flexWrap="wrap">
                <Typography variant="text_sm_black">{success} </Typography>
              </Box>
            ))}
          </Box>
        )}
        {faillureAdding?.length > 0 && (
          <Box padding="16px" borderRadius="8px" backgroundColor={colors?.main} maxWidth="500px" display="flex" flexDirection="column" gap="10px">
            <Box display="flex" alignItems="center" justifyContent="end">
              <IconButton onClick={() => setFaillureAdding([])}>
                <CloseOutlined sx={{ color: "white" }} />
              </IconButton>
            </Box>
            {faillureAdding?.map((failed) => (
              <Box display="flex" gap="8px" flexWrap="wrap">
                <Typography variant="text_sm_white">{failed} </Typography>
              </Box>
            ))}
          </Box>
        )}
        <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
          <input
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            multiple
            accept="image/jpeg , image/png , image/jpg"
            onChange={(event) => {
              console.log(event?.target?.files);
              setFiles([...files, ...event?.target?.files]);
            }}
            style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute", cursor: "pointer" }}
            id="avatar-input"
            type="file"
          />
          <Box justifyContent="center" display="flex" sx={{ cursor: "pointer" }}>
            <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="2px dashed #DBDADE" borderRadius="6px">
              <img src={uploadIcon} alt="upload" />
              <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                {/* {!files.length && "Drop files here or click to upload"}
                                {files.length > 0 && (
                                  <ul>
                                    {files.map((file, index) => (
                                      <li key={index}>{file.name}</li>
                                    ))}
                                  </ul>
                                )} */}
                {t("forms.labels.drop_files")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="16px 0px">
          {files?.map((file, index) => (
            <Box key={index} position="relative">
              <Avatar variant="rounded" src={URL.createObjectURL(file)} sx={{ height: "150px", width: "150px" }} />
              <Button
                variant="secondary_close"
                onClick={() => {
                  DeleteFile(index);
                }}
                sx={{ position: "absolute", top: -10, right: -10, width: "25px", height: "25px", padding: "5px" }}
              >
                <CloseRoundedIcon htmlColor="gray" fontSize={"30px"} />
              </Button>
            </Box>
          ))}
        </Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              addFiles();
            }}
            disabled={progressUpdatePic > 0}
          >
            {t("forms.submit.save")} {progressUpdatePic > 0 && <CircularProgress size={"small"} />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
export default AddImagesBulk;
