import { tableCellClasses, TableCell } from "@mui/material";
import styled from "@emotion/styled";
import moment from "moment";
import placeholderImage from "../../assets/placeholder-4-300x200.png";
import Barcode from "react-barcode";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    letterSpacing: "1px",
    color: `rgba(75,70,92,0.8)`,
    fontSize: 16,
    fontWeight: 500,
    background: "lightgray",
  },
  [`&.${tableCellClasses.body}`]: {
    background: "white",
    fontSize: 16,
    color: `rgba(75,70,92,0.8)`,
    fontWeight: 600,
  },
}));
const StyledCellComponent = ({ object, index, type }) => {
  const entries = Object.entries(object);
  console.log(entries);

  return <>{index > 0 && <StyledTableCell align="left">{type === "date" ? moment(entries[index][1]).format("MMMM DD, yyyy HH:mm") : type === "barcode" ? <Barcode value={entries[index][1]} /> : type === "file" ? entries[index][1]?.includes("mediafiles") ? <img style={{ height: "200px", width: "200px" }} src={entries[index][1]} /> : <img style={{ height: "200px", width: "200px" }} src={placeholderImage} /> : entries[index][1]}</StyledTableCell>}</>;
};
export default StyledCellComponent;
