export const columns_produits_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DÉSIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "RAPPEL",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "CRÉÉ PAR",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "TRAITÉ PAR",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "treated_by",
  },
  {
    name: "TRAITÉ",
    width: "5%",
    align: "left",
    type: "string",
    original_name: "treated",
  },
];
export const columns_produits_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "RECALL",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "CREATED BY",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "TREATED BY",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "treated_by_full_name",
  },
  {
    name: "TREATED",
    width: "5%",
    align: "left",
    type: "string",
    original_name: "treated",
  },
];
export const columns_produits_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNACIÓN",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMATO",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "RETIRADO",
    width: "15%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "CREADO POR",
    width: "10%",
    align: "left",
    type: "string",
    original_name: "created_by_full_name",
  },
  {
    name: "TRATADO POR",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "treated_by_full_name",
  },
  {
    name: "TRATADO",
    width: "5%",
    align: "left",
    type: "string",
    original_name: "treated",
  },
];
