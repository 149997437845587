import { Alert, Autocomplete, Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { columns_produit_en, columns_produit_es, columns_produit_fr } from "../../../columns/produitColumns";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { categories_list_en, categories_list_es, categories_list_fr } from "../../../assets/data/categories";

import debounce from "debounce";
const ProduitList = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  let refresh_token = user?.refresh;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { refreshToken } = useLogin();
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [modification, setModification] = useState(false);
  const [database, setDatabase] = useState(null);
  const [limit, setLimit] = useState({ limit: 25, done: false });
  const [dep, setDep] = useState(null);
  const [departement, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [subDep, setSubDep] = useState(null);
  const [update, setUpdate] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const lang = localStorage.getItem("i18nextLng");
  const columns = lang === "fr" ? columns_produit_fr : lang === "en" ? columns_produit_en : columns_produit_es;
  const [category, setCategory] = useState({ value: "", name: "" });
  const categories = lang === "fr" ? categories_list_fr : lang === "en" ? categories_list_en : categories_list_es;

  useEffect(() => {
    Api.get(Urls?.GET_BDS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit?.limit,
      },
    })
      .then((res) => {
        if (limit.done) {
          setDatabases(res?.data?.results);
          setDatabase(res?.data?.results[0]);
        } else {
          setLimit({ limit: res?.data?.count, done: true });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        }
      });
  }, [modification, limit]);
  useEffect(() => {
    if (loadingData === false) {
      Api.get(Urls?.GetFranchise(database?.franchise_id), {
        headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setDepartments(res?.data?.departments);
          setSubDepartments(res?.data?.sub_departments);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              setUpdate(!update);
            });
          } else {
            setError(err?.response?.data?.detail);
          }
        });
    }
  }, [loadingData, database, update]);
  useEffect(() => {
    if (database?.id) {
      setLoading(true);
      Api.get(Urls?.GetProdByDBID(database?.id), {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: 25,
          ...(dep && { department_number: dep?.number }),
          ...(subDep && { sub_department_number: subDep?.number }),
          q: searchQuery,
          ...(category?.value && { category: category?.value }),
          ...(sorting?.name && { order_by: sorting?.name }),
          ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
        },
      })

        .then((res) => {
          setData((data) => {
            let array = [];
            for (let index = 0; index < res?.data?.results.length; index++) {
              let element = res?.data?.results[index];
              element = { id: element?.id, product_image: element?.use_admin_image ? element.product_image?.product_image : element?.private_product_image, barcode: element?.upc, upc: element?.upc, designation: element?.designation, format: element?.format, ajout: element.updated_at };
              array.push(element);
            }
            return array;
          });
          setResponse(res?.data);
          setLoading(false);
          setLoadingData(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              console.log(res, "new user");
              setRefresh(!refresh);
            });
          } else {
            setError(err?.response?.data?.details);
            setLoadingData(false);
          }
          setLoading(false);
        });
    }
  }, [refresh, database, subDep, dep, searchQuery, category, sorting]);

  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          for (let index = 0; index < res?.data?.results.length; index++) {
            let element = res?.data?.results[index];
            element = { id: element?.id, product_image: element?.use_admin_image ? element.product_image?.product_image : element?.private_product_image, barcode: element?.upc, upc: element?.upc, designation: element?.designation, format: element?.format, ajout: element.updated_at };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((search) => {
      setSearchQuery(search);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(search);
  }, [search]);
  const Buttons = ({ id }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/admin/gestion-des-produits/liste/" + id)}>
          <ReadMoreIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box maxWidth="500px" display="flex" flexDirection="column" gap="10px">
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
            {t("forms.labels.bd")}
          </Typography>
          <Autocomplete
            sx={{ maxWidth: "262px" }}
            value={database}
            options={databases}
            disableClearable={true}
            getOptionLabel={(e) => e?.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(event, value) => {
              if (value !== null) {
                setLoadingData(true);
                setDatabase(value);
                setDep(null);
                setSubDep(null);
              }
            }}
            renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.bd")} />}
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
          <Box>
            <Typography sx={{ fontWeight: "600", color: colors.main }}>UPC / {t("forms.labels.designation")}</Typography>{" "}
          </Box>
          <TextField
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder="01564879 / biscuit"
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", gap: "10px" }}>
          <Box>
            <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
              {t("forms.labels.category")}
            </Typography>
          </Box>
          <Box>
            <Autocomplete
              sx={{ minWidth: "262px", maxWidth: "263px" }}
              options={categories}
              value={category}
              getOptionLabel={(e) => e?.name}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              onChange={(event, value) => {
                if (value !== null) {
                  setCategory(value);
                } else {
                  setCategory(null);
                }
              }}
              renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.category")} />}
            />
          </Box>
        </Box>
      </Box>

      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default ProduitList;
