export const action_types_en = {
  LO: "LOSS",
  CR: "CREDITABLE",
  FH: "FOOD HERO",
  TD: "DEPARTMENT TRANSFER",

  LI: "LIQUIDATION",
  FB: "FOOD BANK",
  CD: "CHANGE DATE",
  RE: "RECALLED",
};
export const action_types_fr = {
  LO: "PERTE",
  CR: "CRÉDITABLE",
  FH: "FOOD HERO",
  TD: "TRANSFERT DE DÉPARTEMENT",
  LI: "LIQUIDATION",
  FB: "BANQUE ALIMENTAIRE",
  CD: "CHANGEMENT DE DATE",
  RE: "RAPPELÉ",
};
export const action_types_es = {
  LO: "PÉRDIDA",
  CR: "ACREDITABLE",
  FH: "FOOD HERO",
  TD: "TRANSFERENCIA DE DEPARTAMENTO",
  LI: "LIQUIDACIÓN",
  FB: "BANCO DE ALIMENTOS",
  CD: "CAMBIO_DE_FECHA",
  RE: "RETIRADO DEL MERCADO",
};
export const action_types_list_en = [
  { name: "LOSS", des: "LO" },
  { name: "CREDITABLE", des: "CR" },
  { name: "FOOD HERO", des: "FH" },
  { name: "DEPARTMENT TRANSFER", des: "TD" },
  { name: "LIQUIDATION", des: "LI" },
  { name: "FOOD BANK", des: "FB" },
  { name: "CHANGE DATE", des: "CD" },
  { name: "RECALLED", des: "RE" },
];
export const action_types_list_fr = [
  { name: "PERTE", des: "LO" },
  { name: "CRÉDITABLE", des: "CR" },
  { name: "FOOD HERO", des: "FH" },
  { name: "TRANSFERT DE DÉPARTEMENT", des: "TD" },

  { name: "LIQUIDATION", des: "LI" },
  { name: "BANQUE ALIMENTAIRE", des: "FB" },
  { name: "CHANGEMENT DE DATE", des: "CD" },
  { name: "RAPPELÉ", des: "RE" },
];
export const action_types_list_es = [
  { name: "PÉRDIDA", des: "LO" },
  { name: "ACREDITABLE", des: "CR" },
  { name: "FOOD HERO", des: "FH" },
  { name: "TRANSFERENCIA DE DEPARTAMENTO", des: "TD" },
  { name: "LIQUIDACIÓN", des: "LI" },
  { name: "BANCO DE ALIMENTOS", des: "FB" },
  { name: "CAMBIO_DE_FECHA", des: "CD" },
  { name: "RETIRADO DEL MERCADO", des: "RE" },
];
