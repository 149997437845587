import { Box, CircularProgress } from "@mui/material";
import { colors } from "../../theme/colors";

const Loading = ({ size, color }) => {
  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress sx={{ color: color ? color : colors?.main }} size={size ? size : 40} />
    </Box>
  );
};
export default Loading;
