import { Alert, Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton, List, ListItem, Modal, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { colors } from "../../../theme/colors";
import * as yup from "yup";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Urls } from "../../../api/Urls";
import Api from "../../../api/Api";
import { useLogin } from "../../../hooks/useLogin";
import Loading from "../../../components/ui/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { modalStyle } from "../../../theme/ModalStyle";
import { CloseOutlined } from "@mui/icons-material";

const LanceurEdit = ({ type }) => {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState({ state: true, number: 10 });
  const [limitDbs, setLimitDbs] = useState({ state: true, number: 10 });
  const [db, setDb] = useState({});
  const [loading, setLoading] = useState({
    loadingBanners: true,
    loadingLanceur: true,
    loadingdbs: true,
    loaded: false,
  });
  const [openDelte, setOpenDelete] = useState({ state: false, data: null });
  const [openUpdate, setOpenUpdate] = useState({ state: false, data: null, page: null });

  const [franchise, setFranchise] = useState({ name: "", id: "" });
  const [error, setError] = useState("");
  const [sendToUser, setSendToUser] = useState({ username: false, password: false });
  const { refreshToken } = useLogin();
  const { user } = useAuthContext();
  const refresh_token = user?.refresh;
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);
  const { id } = useParams();
  const [successCred, setSuccessCred] = useState("");
  const [errorCred, setErrorCred] = useState("");
  const [alertLauncher, setAlerteLauncher] = useState({});
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const [bds, setBds] = useState([]);
  const access_token = user?.access;
  const [modification, setModification] = useState({ type: "refresh", state: false, count: 0, data: null });
  const { t } = useTranslation();
  const [initial_values, setIntialValues] = useState({});
  const [initialValuesUpdateCredentialsEmail, setinitialValuesUpdateCredentialsEmail] = useState({
    courriel: "",
  });
  const [initialValuesUpdateCredentialsUser, setinitialValuesUpdateCredentialsUser] = useState({
    username: "",
  });
  const initialValuesUpdateCredentialsPass = {
    password: "",
  };
  const validationSchema = yup.object({
    banniere: yup?.object({
      id: yup?.string().required(t("validation_required")),
    }),
    entreprise: yup?.string().required(t("validation_required")),
    responsable_first_name: yup?.string().required(t("validation_required")),
    responsable_last_name: yup?.string().required(t("validation_required")),
    adresse: yup?.string().required(t("validation_required")),
    ville: yup?.string().required(t("validation_required")),
    code_postal: yup?.string().required(t("validation_required")),
    cellulaire: yup?.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
    telephone: yup?.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
    bd: yup?.object({
      id: yup?.string().required(t("validation_required")),
    }),
  });
  const validationSchemaUpdateEmail = yup?.object({
    courriel: yup?.string().email(t("forms.validation.email")).required(t("validation_required")),
  });
  const validationSchemaUpdateUsername = yup?.object({
    username: yup?.string().required(t("validation_required")),
  });
  const validationSchemaUpdateCredPass = yup?.object({
    password: yup?.string().min(8, t("forms.validation.8_char_min")).required(t("validation_required")),
  });
  useEffect(() => {
    if (modification?.type === "submit" && modification?.data) {
      let data = modification?.data;
      handleSubmit(data);
    }
  }, [modification]);
  //TRIGGER THE DELETE FUNCTIONS IF THE TOKEN IS NOT VALIDE
  useEffect(() => {
    if (modification?.type === "submit-delete-lanceur") {
      handleDelete();
    }
  }, [modification]);
  useEffect(() => {
    if (modification?.type === "submit-cred-username" && modification?.data) {
      let data = modification?.data;
      handleSubmitCreduser(data);
    }
  }, [modification]);
  useEffect(() => {
    if (modification?.type === "submit-cred-pass" && modification?.data) {
      let data = modification?.data;
      handleSubmitCredPass(data);
    }
  }, [modification]);
  useEffect(() => {
    if (modification?.type === "submit-cred-email" && modification?.data) {
      let data = modification?.data;
      handleSubmitCredEmail(data);
    }
  }, [modification]);
  useEffect(() => {
    if (limit?.state === false) {
      handleGetAllBanners();
    }
  }, [limit]);
  useEffect(() => {
    setLoading({
      loadingBanners: true,
      loadingLanceur: true,
      loadingdbs: true,
      loaded: false,
    });
    if (id) {
      Api.get(Urls?.GetAlertLauncherByID(id), {
        headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setAlerteLauncher(res?.data);
          setIntialValues({
            banniere: { name: res?.data?.franchise?.name, id: res?.data?.franchise?.id },
            entreprise: res?.data?.enterprise_name,
            responsable_first_name: res?.data?.responsible_first_name,
            responsable_last_name: res?.data?.responsible_last_name,
            adresse: res?.data?.address,
            ville: res?.data?.city,
            code_postal: res?.data?.postal_code,
            telephone: res?.data?.phone_number,
            cellulaire: res?.data?.cellular_number,
            bd: { id: res?.data?.product_db?.id, name: res?.data?.product_db?.name },
          });
          setinitialValuesUpdateCredentialsEmail({
            courriel: res?.data?.user?.email,
          });
          setinitialValuesUpdateCredentialsUser({
            username: res?.data?.user?.username,
          });
          setFranchise(res?.data?.franchise);
          setDb(res?.data?.product_db);
          setLoading({
            loadingBanners: true,
            loadingLanceur: false,
            loadingdbs: false,
          });
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              setUpdate(!update);
            });
          } else {
            setError(t("error_500"));
          }
        });
    }
  }, [update]);

  useEffect(() => {
    if (limitDbs?.state === false) {
      handleGetAllDbs();
    }
  }, [modification, limitDbs]);
  useEffect(() => {
    setError("");
    if (loading?.loadingLanceur === false) {
      Api.get(Urls?.GET_BANNIERES, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: limit?.number,
        },
      })

        .then((res) => {
          setData((data) => {
            return res?.data?.results;
          });
          setLimit({ state: false, number: res?.data?.count });
          setLoading({
            loadingBanners: false,
            loadingLanceur: true,
            loadingdbs: true,
            loaded: false,
          });
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "refresh", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setError(err?.response?.data?.details);
          }
        });
    }
  }, [loading]);
  useEffect(() => {
    setError("");
    if (franchise?.id && loading?.loadingBanners === false) {
      Api.get(Urls?.GetDbsByFrID(franchise?.id), {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: limitDbs?.number,
          is_master: true,
        },
      })

        .then((res) => {
          setLimitDbs({ state: false, number: res?.data?.count });
          setLoading({
            loadingBanners: true,
            loadingLanceur: true,
            loadingdbs: true,
            loaded: true,
          });
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "refresh-db", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setError(err?.response?.data?.details);
          }
        });
    }
  }, [franchise, loading]);
  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmit = (values) => {
    handleClickCloseUpdate();
    setError(null);
    Api.patch(
      Urls.UpdateAlertLauncher(id),
      {
        franchise_id: values?.banniere?.id,
        enterprise_name: values?.entreprise,
        responsible_first_name: values?.responsable_first_name,
        responsible_last_name: values?.responsable_last_name,
        address: values?.adresse,
        city: values?.ville,
        postal_code: values?.code_postal,
        phone_number: values?.telephone,
        cellular_number: values?.cellulaire,
        product_db_id: values?.bd?.id,
        franchise_id: values?.banniere?.id,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));

        setTimeout(() => {
          setSuccess("");
          setUpdate(!update);
        }, 3000);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
        }
      });
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      enterprise_name: error?.enterprise_name,
      address: error?.address,
      city: error?.city,
      postal_code: error?.postal_code,
      phone_number: error?.phone_number,
      cellular_number: error?.cellular_number,
      responsible_first_name: error?.responsible_first_name,
      responsible_last_name: error?.responsible_last_name,
      product_bd_id: error?.product_bd_id,
      franchise_id: error?.franchise_id,
    });
  }
  //HANDLE ERROR
  function handleErrorCred(error) {
    setErrorCred({
      username: error?.username,
      password: error?.password,
      email: error?.email,
    });
  }
  const handleGetAllBanners = () => {
    Api.get(Urls?.GET_BANNIERES, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limit?.number,
      },
    })

      .then((res) => {
        setData((data) => {
          return res?.data?.results;
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification({ type: "pagination", state: !modification?.state, count: modification?.count + 1, data: null });
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  };
  const handleGetAllDbs = () => {
    Api.get(Urls?.GetDbsByFrID(franchise?.id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: limitDbs?.number,
        has_manager: false,
        is_master: true,
      },
    })

      .then((res) => {
        setBds(res?.data?.results);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification({ type: "pagination-dbs", state: !modification?.state, count: modification?.count + 1, data: null });
          });
        } else {
          setError(err?.response?.data?.details);
        }
      });
  };
  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCredEmail = (values) => {
    handleClickCloseUpdate();
    setErrorCred(null);
    if (values?.courriel !== initialValuesUpdateCredentialsEmail?.courriel) {
      Api.patch(
        Urls.UpdateUserEmail(alertLauncher?.user?.id),
        {
          email: values?.courriel,
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          setSuccessCred(t("forms.success.update"));
          console.log("test test hdjfhjdhfjhj");
          setTimeout(() => {
            setSuccessCred("");
            setUpdate(!update);
          }, 3000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "submit-cred-email", state: !modification?.state, count: modification.count + 1, data: values };
              setModification(newState);
            });
          } else {
            handleErrorCred(err?.response?.data);
          }
        });
    }
  };

  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCreduser = (values) => {
    handleClickCloseUpdate();
    setErrorCred(null);
    if (values?.username !== initialValuesUpdateCredentialsUser?.username) {
      Api.patch(
        Urls.UpdateUserUsername(alertLauncher?.user?.id),
        {
          ...(values?.username && values?.username !== initialValuesUpdateCredentialsUser?.username && { username: values?.username }),
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
          params: {
            send_to_user: sendToUser?.username,
          },
        }
      )
        .then((res) => {
          setSuccessCred(t("forms.success.update"));
          setTimeout(() => {
            setSuccessCred("");
            setUpdate(!update);
          }, 3000);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "submit-cred-username", state: !modification?.state, count: modification.count + 1, data: values };
              setModification(newState);
            });
          } else {
            setLoading(false);
            handleErrorCred(err?.response?.data);
          }
        });
    }
  };
  //HANDLE SBUMIT EDIT MANAGER
  const handleSubmitCredPass = (values) => {
    handleClickCloseUpdate();

    setErrorCred(null);
    Api.patch(
      Urls.UpdateUserPassword(alertLauncher?.user?.id),
      {
        password: values?.password,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
        params: {
          send_to_user: sendToUser?.password,
        },
      }
    )
      .then((res) => {
        setSuccessCred(t("forms.success.update"));

        setTimeout(() => {
          setSuccessCred("");
          setUpdate(!update);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit-cred-pass", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleErrorCred(err?.response?.data);
        }
      });
  };
  const handleClickCloseUpdate = () => {
    setOpenUpdate({ state: false, data: null, page: null });
  };
  const handleClickCloseDelete = () => {
    setOpenDelete({ state: false, data: null });
  };
  //HANDLE DELETE MARCHAND
  const handleDelete = () => {
    handleClickCloseDelete();
    Api.delete(Urls?.DeleteMarchandByID(id), {
      headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        navigate("/admin/gestion-des-marchands/liste");
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit-delete-lanceur", state: !modification?.state, count: modification.count + 1, data: null };
            setModification(newState);
          });
        } else {
          setError(t("error_500"));
        }
      });
  };
  return (
    <Box>
      {!loading?.loaded && <Loading />}
      {loading?.loaded && (
        <Box display="flex" flexDirection="column" gap="24px">
          <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "profile" })}>
            {({ errors, values, setFieldValue }) => (
              <Form>
                <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.banniere")}
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          sx={{ minWidth: "262px" }}
                          value={franchise}
                          options={data}
                          getOptionLabel={(e) => e?.name}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setFieldValue("banniere", value);
                              setFranchise(value);
                              setLoading({
                                loadingLanceur: true,
                                loadingBanners: false,
                                loadingdbs: false,
                              });
                              setFieldValue("bd", { name: "", id: "" });
                              setDb({ name: "", id: "" });
                            } else {
                              setFieldValue("banniere", null);
                              setFranchise({ name: "", id: "" });
                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.banniere")} />}
                        />
                        <ErrorMessage name="banniere.id" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.bd")}
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          sx={{ minWidth: "262px" }}
                          value={db}
                          options={bds}
                          getOptionLabel={(e) => e?.name}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setFieldValue("bd", value);
                              setDb(value);
                            } else {
                              setFieldValue("bd", null);
                              setDb({ name: "", id: "" });
                            }
                          }}
                          renderInput={(params) => <TextField fullWidth {...params} name="autocomplete" placeholder={t("forms.labels.bd")} />}
                        />
                        <ErrorMessage name="bd.id" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.entreprise")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="entreprise" type="input" placeholder={t("forms.labels.entreprise")} as={TextField} />
                        <ErrorMessage name="entreprise" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.responsable")} {t("forms.labels.nom")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="responsable_last_name" type="input" placeholder={t("forms.labels.responsable")} as={TextField} />
                        <ErrorMessage name="responsable_last_name" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.responsable")} {t("forms.labels.prenom")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="responsable_first_name" type="input" placeholder={t("forms.labels.responsable")} as={TextField} />
                        <ErrorMessage name="responsable_first_name" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.adresse")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="adresse" type="input" placeholder={t("forms.labels.adresse")} as={TextField} />
                        <ErrorMessage name="adresse" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.ville")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="ville" placeholder={t("forms.labels.ville")} as={TextField} />
                        <ErrorMessage name="ville" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.code_postal")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="code_postal" placeholder={t("forms.labels.code_postal")} as={TextField} />
                        <ErrorMessage name="code_postal" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.cellulaire")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="cellulaire" type="input" placeholder={t("forms.labels.cellulaire")} as={TextField} />
                        <ErrorMessage name="cellulaire" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" type="input" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.telephone")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="telephone" type="input" placeholder={t("forms.labels.telephone")} as={TextField} />
                        <ErrorMessage name="telephone" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>

                  {success && <Alert severity="success">{success}</Alert>}
                  {error && (
                    <Alert severity="error">
                      <Box>
                        <List>
                          {error?.franchise_id && (
                            <ListItem>
                              {t("forms.labels.banniere")} : {error?.franchise_id}
                            </ListItem>
                          )}

                          {error?.enterprise_name && (
                            <ListItem>
                              {t("forms.labels.entreprise")} : {error?.enterprise_name}
                            </ListItem>
                          )}
                          {error?.responsible_first_name && (
                            <ListItem>
                              {t("forms.labels.responsable")} : {error?.responsible_first_name}
                            </ListItem>
                          )}
                          {error?.responsable_last_name && (
                            <ListItem>
                              {t("forms.labels.responsable")} : {error?.responsable_last_name}
                            </ListItem>
                          )}
                          {error?.address && (
                            <ListItem>
                              {t("forms.labels.adresse")} : {error?.address}
                            </ListItem>
                          )}
                          {error?.city && (
                            <ListItem>
                              {t("forms.labels.ville")} : {error?.city}
                            </ListItem>
                          )}
                          {error?.postal_code && (
                            <ListItem>
                              {t("forms.labels.code_postal")} : {error?.postal_code}
                            </ListItem>
                          )}
                          {error?.phone_number && (
                            <ListItem>
                              {t("forms.labels.telephone")} : {error?.phone_number}
                            </ListItem>
                          )}
                          {error?.cellular_number && (
                            <ListItem>
                              {t("forms.labels.cellulaire")} : {error?.cellular_number}
                            </ListItem>
                          )}

                          {error?.product_bd_id && (
                            <ListItem>
                              {t("forms.labels.bd")} : {error?.product_bd_id}
                            </ListItem>
                          )}
                        </List>
                      </Box>
                    </Alert>
                  )}
                  <Box>
                    <Button type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Formik initialValues={initialValuesUpdateCredentialsEmail} enableReinitialize validationSchema={validationSchemaUpdateEmail} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "email" })}>
            {({ errors }) => (
              <Form>
                <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                  <Box>
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.courriel")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field type="input" name="courriel" placeholder={t("forms.labels.courriel")} as={TextField} />
                        <ErrorMessage name="courriel" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Button type="submit" variant="primary">
                      {t("forms.submit.save")}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Formik initialValues={initialValuesUpdateCredentialsUser} enableReinitialize validationSchema={validationSchemaUpdateUsername} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "username" })}>
            {({ errors }) => (
              <Form>
                <Box>
                  <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                    <Box>
                      <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                        <Box flex={1}>
                          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                            {t("forms.labels.utilisateur")}
                          </Typography>
                        </Box>
                        <Box>
                          <Field name="username" placeholder={t("forms.labels.utilisateur")} as={TextField} />
                          <ErrorMessage name="username" component={FormikErrorText} color={colors.main} width="250px" />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Button type="submit" variant="primary">
                        {t("forms.submit.save")}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendToUser?.username}
                          value={sendToUser?.username}
                          onChange={(e) => {
                            console.log(e?.target?.checked);
                            setSendToUser((sendToUser) => {
                              return { username: e.target?.checked, password: sendToUser?.password };
                            });
                          }}
                        />
                      }
                      label={t("send_by_email")}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>

          <Formik initialValues={initialValuesUpdateCredentialsPass} enableReinitialize validationSchema={validationSchemaUpdateCredPass} onSubmit={(values) => setOpenUpdate({ state: true, data: values, page: "password" })}>
            {({ errors }) => (
              <Form>
                <Box>
                  <Box maxWidth="700px" display="flex" flexDirection="row" gap="16px" flexWrap="wrap" alignItems="end">
                    <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                      <Box flex={1}>
                        <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                          {t("forms.labels.mot_de_passe")}
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="password" placeholder={t("forms.labels.mot_de_passe")} as={TextField} />
                        <ErrorMessage name="password" component={FormikErrorText} color={colors.main} width="250px" />
                      </Box>
                    </Box>
                    <Box>
                      <Button type="submit" variant="primary">
                        {t("forms.submit.save")}
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendToUser?.password}
                          value={sendToUser?.password}
                          onChange={(e) => {
                            console.log(e?.target?.checked);
                            setSendToUser((sendToUser) => {
                              return { username: sendToUser?.username, password: e.target?.checked };
                            });
                          }}
                        />
                      }
                      label={t("send_by_email")}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
          <Box maxWidth="500px">
            {successCred && <Alert severity="success">{successCred}</Alert>}
            {errorCred && (
              <Alert severity="error">
                <Box>
                  <List>
                    {errorCred?.username && (
                      <ListItem>
                        {t("forms.labels.utilisateur")} : {errorCred?.username}
                      </ListItem>
                    )}
                    {errorCred?.password && (
                      <ListItem>
                        {t("forms.labels.mot_de_passe")} : {errorCred?.password}
                      </ListItem>
                    )}
                    {errorCred?.detail && <ListItem>{errorCred?.detail}</ListItem>}
                    {errorCred?.email && (
                      <ListItem>
                        {t("forms.labels.courriel")} : {errorCred?.email}
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Alert>
            )}
          </Box>
          <Box>
            <Button onClick={() => setOpenDelete({ state: true, data: id })} variant="primary">
              {t("forms.submit.delete")}
            </Button>
          </Box>
        </Box>
      )}
      {/* modal confirmation update */}
      <Modal open={openUpdate?.state} onClose={handleClickCloseUpdate}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseUpdate}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.save")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.update")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="20px">
              <Button
                onClick={() => {
                  openUpdate?.page === "profile" ? handleSubmit(openUpdate?.data) : openUpdate?.page === "email" ? handleSubmitCredEmail(openUpdate?.data) : openUpdate?.page === "username" ? handleSubmitCreduser(openUpdate?.data) : handleSubmitCredPass(openUpdate?.data);
                }}
                variant="primary"
              >
                {t("forms.submit.save")}
              </Button>
              <Button onClick={handleClickCloseUpdate} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* modal confirmation update */}
      <Modal open={openDelte?.state} onClose={handleClickCloseDelete}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseDelete}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.delete")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.delete")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="20px">
              <Button
                onClick={() => {
                  handleDelete();
                }}
                variant="primary"
              >
                {t("forms.submit.delete")}
              </Button>
              <Button onClick={handleClickCloseDelete} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default LanceurEdit;
