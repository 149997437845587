export const columns_produits_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "RAPPEL",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DÉSIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "NOTE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "note",
  },
];
export const columns_produits_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "RECALL",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNATION",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },
  {
    name: "FORMAT",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "NOTE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "note",
  },
];
export const columns_produits_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "RETIRADO",
    width: "10%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "UPC",
    width: "10%",
    align: "left",
    type: "number",
    original_name: "upc",
  },
  {
    name: "DESIGNACIÓN",
    width: "15%",
    align: "left",
    type: "string",
    original_name: "designation",
  },

  {
    name: "FORMATO",
    width: "10%",
    align: "left",
    type: "poid",
    original_name: "format",
  },
  {
    name: "NOTA",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "note",
  },
];
