import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import logo_fr from "../../../assets/exppro-logo-600px-francais-white.svg";
import logo_en from "../../../assets/exppro-logo-600px-anglais-white.svg";
import logo_es from "../../../assets/exppro-logo-600px-spanish-white.svg";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useLogin } from "../../../hooks/useLogin";
import { toast } from "react-toastify";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../../components/ui/LanguageSelector";
import { colors } from "../../../theme/colors";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    username: "",
    password: "",
  };
  const { t } = useTranslation();
  const validationSchema = yup.object({
    username: yup.string().required(t("validation_required")),
    password: yup.string().required(t("validation_required")),
  });
  const { login, errorLogin, isLoading } = useLogin();
  const onSubmitLogin = async (values, errorLogin, isLoading) => {
    await login(values.username, values.password);
    toast.error(errorLogin);
  };
  const handleNavigateLostPass = () => {
    navigate("/mot-de-passe-oublier");
  };
  return (
    <Box width="100%" minHeight="100vh" sx={{ background: colors?.gradient180 }}>
      <Box width="100%" display="flex" justifyContent="end">
        <LanguageSelector />
      </Box>
      <Box width="100%">
        <Box maxWidth="600px" display="flex" justifyContent="center" margin="100px auto" marginBottom="0px">
          {t("logo") === "logo_fr" && <img src={logo_fr} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
          {t("logo") === "logo_en" && <img src={logo_en} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
          {t("logo") === "logo_es" && <img src={logo_es} style={{ aspectRatio: "2/1", maxWidth: "100%" }} alt="expo" />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box height="fit-content">
          <Formik onSubmit={onSubmitLogin} initialValues={initialValues} validationSchema={validationSchema}>
            {({ errors }) => (
              <Form>
                <Box display="flex" flexDirection="column" gap="32px">
                  <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                    <Box flex={1}>
                      <Typography variant="text_lg_white">{t("user_textField")}</Typography>
                    </Box>
                    <Box>
                      <Field type="input" name="username" placeholder={t("user_textField")} as={TextField} autoComplete="off" />
                      <ErrorMessage name="username" component={FormikErrorText} />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, gap: { xs: "10px", sm: "10px", md: "32px", lg: "32px" } }}>
                    <Box flex={1}>
                      <Typography variant="text_lg_white">{t("user_password")}</Typography>
                    </Box>
                    <Box>
                      <Field type="password" name="password" placeholder={t("user_password")} as={TextField} autoComplete="off" />
                      <ErrorMessage name="password" component={FormikErrorText} />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="center">
                    <Button disabled={isLoading} variant="white" type="submit">
                      {t("login")}
                    </Button>
                  </Box>
                  {errorLogin && (
                    <Box display="flex" justifyContent="center">
                      <Alert sx={{ marginTop: "1rem" }} severity="error">
                        {errorLogin}
                      </Alert>
                    </Box>
                  )}
                  <Box sx={{ textAlign: "center" }}>
                    <Box onClick={handleNavigateLostPass}>
                      <Typography variant="text_md_white" sx={{ cursor: "pointer", textDecoration: "underline", fontWeight: "600" }}>
                        {t("lost_password")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};
export default Login;
