import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { theme } from "./theme/Theme.js";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { AuthContextProvider } from "./context/AuthContext.js";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { Box } from "@mui/material";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["fr", "en", "es"],
    backend: { loadPath: "/assets/locales/{{lng}}/global.json" },
    fallbackLng: "fr",
    detection: {
      order: ["localStorage", "path", "subdomain"],
      caches: ["localStorage"],
    },
    react: { useSuspense: true },
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Box>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Box>
);
