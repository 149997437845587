export const columns_bannieres_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANNIÈRE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
];
export const columns_bannieres_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANNER",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
];
export const columns_bannieres_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "BANDERA",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
];
