import React, { useState } from "react";
import i18next from "i18next";
import { Autocomplete, Box, TextField } from "@mui/material";
function LanguageSelector({ margin }) {
  const [currentLangCode, setCurrentLangCode] = useState(localStorage.getItem("i18nextLng")?.toUpperCase());
  const options = ["FR", "EN", "ES"];
  const handleChangeLang = (lang) => {
    if (lang === "FR") {
      i18next.changeLanguage("fr");
      setCurrentLangCode("FR");
    }
    if (lang === "EN") {
      i18next.changeLanguage("en");
      setCurrentLangCode("EN");
    }
    if (lang === "ES") {
      i18next.changeLanguage("es");
      setCurrentLangCode("ES");
    }
    window.location.reload();
  };
  return (
    <Box position="relative" maxWidth="100px" margin={margin ? margin : "16px"}>
      <Autocomplete
        disableClearable
        options={options}
        value={currentLangCode}
        getOptionLabel={(e) => e}
        onChange={(event, value) => {
          if (value !== null) {
            handleChangeLang(value);
          }
        }}
        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Langue" />}
      />
    </Box>
  );
}

export default LanguageSelector;
