import { Alert, Box, Button, CircularProgress, IconButton, List, ListItem, Modal, TextField, Typography } from "@mui/material";
import { useLogin } from "../../../hooks/useLogin";
import { colors } from "../../../theme/colors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import SaveIcon from "@mui/icons-material/Save";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/ui/Loading";
import { CloseOutlined } from "@mui/icons-material";
import { modalStyle } from "../../../theme/ModalStyle";

const AjoutBanniere = ({ type }) => {
  const { user } = useAuthContext();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(id ? true : false);
  let userContext = user;
  const { refreshToken } = useLogin();
  const [banniere, setBanniere] = useState("");
  const [error, setError] = useState("");
  const [errorDep, setErrorDep] = useState("");
  const [successDep, setSuccessDep] = useState("");
  const [errorSubDep, setErrorSubDep] = useState("");
  const [successSubDep, setSuccessSubDep] = useState("");
  const lang = localStorage?.getItem("i18nextLng");
  const [success, setSuccess] = useState("");
  const [modification, setModification] = useState({ type: "", state: false, count: 0 });
  const [update, setUpdate] = useState(false);
  const [deleteU, setDeleteU] = useState({ state: false, count: 0 });
  const navigate = useNavigate();
  const [triggerSub, setTriggerSub] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [DepFields, setDepFields] = useState([
    {
      name: 0,
      value: "",
    },
  ]);
  const [subDepFields, setSubDepFields] = useState([
    {
      name: 0,
      value: "",
    },
  ]);
  const { t } = useTranslation();

  //useEffect

  //TRIGGER THE DELETE FUNCTIONS IF THE TOKEN IS NOT VALIDE
  useEffect(() => {
    if (deleteU?.count !== 0) {
      handleDelete();
    }
  }, [deleteU]);
  //TRIGGER THE UPDATE OR SUBMIT FUNCTIONS IF THE TOKEN IS NOT VALIDE
  useEffect(() => {
    if (modification?.count !== 0 && modification?.type === "submit") {
      console.log("executed");
      handleSubmit();
    }
    if (modification?.count !== 0 && modification?.type === "update") {
      console.log("executed");
      handleUpdate();
    }
    if (modification?.count !== 0 && modification?.type === "update-dep") {
      handleUpdateDep(modification?.data, modification?.index);
    }
    if (modification?.count !== 0 && modification?.type === "update-sub-dep") {
      handleUpdateSubDep(modification?.data, modification?.index);
    }
  }, [modification]);

  //TRIGGER THE GET FUNCTION IF THE TOKEN IS NOT VALIDE
  useEffect(() => {
    if (id && type === "update") {
      Api.get(Urls?.GetFranchise(id), {
        headers: { "Content-Type": "application/json", "Accept-language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
      })
        .then((res) => {
          setBanniere(res?.data?.name);
          organizeFields(res);
          setLoadingData(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(userContext?.refresh).then((res) => {
              setUpdate(!update);
            });
          } else {
            setLoadingData(false);
          }
        });
    }
  }, [update]);
  //handlers
  //ORGANIZE FIELDS FOR UPDATE
  const [originalDepFields, setOriginalDepFields] = useState([]);
  const [originalSubDepFields, setOriginalSubDepFields] = useState([]);
  function organizeFields(res) {
    let array_of_deps = res?.data?.departments;
    let array_of_subDeps = res?.data?.sub_departments;
    let array_of_deps_fields = [];
    let array_of_subDeps_fields = [];
    for (let index = 0; index < array_of_deps.length; index++) {
      const element = array_of_deps[index];
      array_of_deps_fields.push({ id: element?.id, name: index, value: element?.name, number: element?.number });
    }
    for (let index = 0; index < array_of_subDeps.length; index++) {
      const element = array_of_subDeps[index];
      array_of_subDeps_fields.push({ id: element?.id, name: index, value: element?.name, number: element?.number });
    }
    setDepFields(array_of_deps_fields);
    setOriginalDepFields(array_of_deps_fields);
    setSubDepFields(array_of_subDeps_fields);
    setOriginalSubDepFields(array_of_subDeps_fields);
  }

  //HANDLE CLICK UPDATE BANNIERE
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };

  //HANDLE CLICK CLOSE UPDATE BANNIERE
  const handleClickCloseUpdate = () => {
    setOpenUpdate(false);
  };

  //HANDLE CLICK DELETE BANNIERE
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  //HANDLE CLICK CLOSE DELETE BANNIERE
  const handleClickCloseDelete = () => {
    setOpenDelete(false);
  };

  //HANDLE BANNIERE INPUT
  const handleChangeBanniere = (e) => {
    setBanniere(e.target.value);
  };

  //HANDE DEPARTMENT INPUTS
  const handleChangeDepFields = (e, index) => {
    let array = [...DepFields];
    let depField = array.splice(index, 1)[0];
    console.log(depField, "depField");
    depField = { ...depField, value: e.target.value };
    array.splice(index, 0, depField);
    console.log(array, "array dep Field");
    setDepFields(array);
  };

  //HANDE SUB DEPARTMENT INPUTS
  const handleChangeSubDepFields = (e, index) => {
    let array = [...subDepFields];
    let depField = array.splice(index, 1)[0];
    depField = { ...depField, value: e.target.value };
    array.splice(index, 0, depField);
    setSubDepFields(array);
  };

  //HANDLE SBUMIT CREATE BANNIERE
  const handleSubmit = () => {
    setError(null);
    setLoading(true);
    if (banniere !== "") {
      Api.post(
        Urls.ADD_Franchise,
        {
          name: banniere,
          departments: DepFields.map((dep) => dep.value),
          sub_departments: subDepFields.map((dep) => dep.value),
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
        }
      )
        .then((res) => {
          setSuccess(t("forms.success.add"));
          setTimeout(() => {
            setSuccess("");
          }, 3000);
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(userContext?.refresh).then((res) => {
              const newState = { type: "submit", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setLoading(false);
            setError({ name: err?.response?.data?.name ? err?.response?.data?.name["0"] : "", departments: err?.response?.data?.departments ? err?.response?.data?.departments["0"] : "", sub_departments: err?.response?.data?.sub_departments ? err?.response?.data?.sub_departments["0"] : "" });
          }
        });
    } else {
      setTriggerSub(true);
      setLoading(false);
    }
  };
  //manage dep and sub dep fields after updating one of them by updating the state
  const manageDep = (data, index) => {
    let array_of_deps = [...originalDepFields];
    let depField = array_of_deps.splice(index, 1)[0];
    depField = { ...depField, value: data?.value };
    array_of_deps.splice(index, 0, depField);
    setOriginalDepFields(array_of_deps);
  };
  const manageSubDep = (data, index) => {
    let array_of_deps = [...originalSubDepFields];
    let depField = array_of_deps.splice(index, 1)[0];
    depField = { ...depField, value: data?.value };
    array_of_deps.splice(index, 0, depField);
    setOriginalSubDepFields(array_of_deps);
  };
  //HANDLE SBUMIT UPDATE BANNIERE
  const handleUpdate = () => {
    handleClickCloseUpdate();
    setError(null);
    setLoading(true);
    if (banniere !== "") {
      Api.patch(
        Urls.UpdateFranchise(id),
        {
          name: banniere,
        },
        {
          headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
        }
      )
        .then((res) => {
          setSuccess(t("forms.success.update"));
          setTimeout(() => {
            setSuccess("");
          }, 3000);
          setUpdate(!update);
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(userContext?.refresh).then((res) => {
              const newState = { type: "update", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setLoading(false);
            setError({ details: err?.response?.data?.detail, name: err?.response?.data?.name ? err?.response?.data?.name["0"] : "", departments: err?.response?.data?.departments ? err?.response?.data?.departments["0"] : "", sub_departments: err?.response?.data?.sub_departments ? err?.response?.data?.sub_departments["0"] : "" });
          }
        });
    } else {
      setTriggerSub(true);
      setLoading(false);
    }
  };
  //HANDLE SBUMIT UPDATE departments
  const handleUpdateDep = (dep, index) => {
    setErrorDep(null);
    Api.patch(
      Urls.UpdateDepByID(dep?.id),
      {
        name: dep.value,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
      }
    )
      .then((res) => {
        setSuccessDep(t("forms.success.update"));
        setTimeout(() => {
          setSuccessDep("");
        }, 3000);
        manageDep(dep, index);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(userContext?.refresh).then((res) => {
            const newState = { type: "update-dep", state: !modification?.state, count: modification.count + 1, index: index, data: dep };
            setModification(newState);
          });
        } else {
          setLoading(false);
          setErrorDep({ name: err?.response?.data?.name ? err?.response?.data?.name : "", details: err?.response?.data?.detail });
        }
      });
  };
  //HANDLE SBUMIT UPDATE sub departments
  const handleUpdateSubDep = (dep, index) => {
    setErrorSubDep(null);
    Api.patch(
      Urls.UpdateSubDepByID(dep?.id),
      {
        name: dep.value,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
      }
    )
      .then((res) => {
        setSuccessSubDep(t("forms.success.update"));
        setTimeout(() => {
          setSuccessSubDep("");
        }, 3000);
        manageSubDep(dep, index);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(userContext?.refresh).then((res) => {
            const newState = { type: "update-sub-dep", state: !modification?.state, count: modification.count + 1, index: index, data: dep };
            setModification(newState);
          });
        } else {
          setErrorSubDep({ name: err?.response?.data?.name ? err?.response?.data?.name : "", details: err?.response?.data?.detail });
        }
      });
  };
  //HANDLE DELETE FRANCHISE
  const handleDelete = () => {
    handleClickCloseDelete();
    Api.delete(Urls?.DeleteFranchise(id), {
      headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${userContext?.access}` },
    })
      .then((res) => {
        navigate("/admin/gestion-des-bannieres/liste");
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(userContext?.refresh).then((res) => {
            const newState = { state: !deleteU?.state, count: deleteU + 1 };
            setDeleteU(newState);
          });
        } else {
          setError({ name: err?.response?.data?.name ? err?.response?.data?.name["0"] : "", departments: err?.response?.data?.departments ? err?.response?.data?.departments["0"] : "", sub_departments: err?.response?.data?.sub_departments ? err?.response?.data?.sub_departments["0"] : "" });
        }
      });
  };
  return (
    <Box>
      {loadingData && <Loading />}
      {!loadingData && (
        <Box maxWidth="900px" display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="5px">
            <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
              {t("forms.labels.banniere")}
            </Typography>
            <Box display="flex">
              <Box>
                <TextField value={banniere} onChange={handleChangeBanniere} placeholder="Maxxi" />
                <Box width="100%" display="flex" justifyContent="center">
                  {triggerSub && banniere === "" && <FormikErrorText color={colors.main}>{t("validation_required")}</FormikErrorText>}
                </Box>
              </Box>

              <Box width="50px"></Box>
            </Box>
          </Box>
          {success && <Alert severity="success">{success}</Alert>}
          {error && (
            <Alert severity="error">
              <Box>
                <List>
                  {error?.details && <ListItem>{error?.details}</ListItem>}
                  {error?.name && (
                    <ListItem>
                      {t("forms.labels.banniere")} : {error?.name}
                    </ListItem>
                  )}
                </List>
              </Box>
            </Alert>
          )}
          <Box display="flex" alignItems="center" gap="20px">
            <Button variant="primary" onClick={id ? handleClickOpenUpdate : handleSubmit}>
              {t("forms.submit.save")}
              {loading && (
                <Box display="flex" alignItems="center" marginLeft="10px">
                  <CircularProgress size={20} sx={{ color: "white" }} />
                </Box>
              )}
            </Button>
            {id && type === "update" && (
              <Button variant="primary" onClick={handleClickOpenDelete}>
                {t("forms.submit.delete")}
              </Button>
            )}
          </Box>
          {id && type === "update" && (
            <Box display="flex" gap="24px" flexWrap="wrap">
              {" "}
              <Box>
                <Box display="flex" flexDirection="column" gap="5px">
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.departement")}
                  </Typography>
                  <Box display="flex" alignItems="end" flexDirection="column" gap="5px">
                    {DepFields.map((depField, index) => (
                      <>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" gap="16px" alignItems="center" flex={2}>
                            <Box flex={1}>
                              <Typography>{depField?.number}</Typography>
                            </Box>
                            <TextField onChange={(e) => handleChangeDepFields(e, index)} value={depField?.value} placeholder="Dep 01" />
                          </Box>
                          <Box width="50px" display="flex" justifyContent="center">
                            {depField?.value !== originalDepFields[index].value && depField.value !== "" && (
                              <IconButton onClick={() => handleUpdateDep(depField, index)}>
                                <SaveIcon fontSize="22" />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </>
                    ))}
                  </Box>
                  {successDep && <Alert severity="success">{successDep}</Alert>}
                  {errorDep && (
                    <Alert severity="error">
                      <List>
                        {errorDep?.name && (
                          <ListItem>
                            {t("forms.labels.dep")} : {errorDep?.name}
                          </ListItem>
                        )}
                        {errorDep?.details && <ListItem>{errorDep?.details}</ListItem>}
                      </List>
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column" gap="5px">
                  <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                    {t("forms.labels.sous-dep")}
                  </Typography>
                  <Box display="flex" alignItems="end" flexDirection="column" gap="5px">
                    {subDepFields.map((depField, index) => (
                      <>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center" gap="16px" flex={2}>
                            <Box flex={1}>
                              <Typography>{depField?.number}</Typography>
                            </Box>
                            <TextField onChange={(e) => handleChangeSubDepFields(e, index)} value={depField?.value} placeholder="Dep 01" />
                          </Box>
                          <Box width="50px" display="flex" justifyContent="center">
                            {depField?.value !== originalSubDepFields[index].value && depField.value !== "" && (
                              <IconButton onClick={() => handleUpdateSubDep(depField, index)}>
                                <SaveIcon fontSize="22" />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </>
                    ))}
                  </Box>
                  {successSubDep && <Alert severity="success">{successSubDep}</Alert>}
                  {errorSubDep && (
                    <Alert severity="error">
                      <List>
                        {errorSubDep?.name && (
                          <ListItem>
                            {t("forms.labels.dep")} : {errorSubDep?.name}
                          </ListItem>
                        )}
                        {errorSubDep?.details && <ListItem>{errorSubDep?.details}</ListItem>}
                      </List>
                    </Alert>
                  )}
                </Box>{" "}
              </Box>
            </Box>
          )}
        </Box>
      )}
      {/* modal confirmation update */}
      <Modal open={openUpdate} onClose={handleClickCloseUpdate}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseUpdate}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.save")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.update")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="20px">
              <Button onClick={handleUpdate} variant="primary">
                {t("forms.submit.save")}
              </Button>
              <Button onClick={handleClickCloseUpdate} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* modal confirmation delete */}
      <Modal open={openDelete} onClose={handleClickCloseDelete}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseDelete}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.delete")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.delete")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="24px">
              <Button onClick={handleDelete} variant="primary">
                {t("forms.submit.delete")}
              </Button>
              <Button onClick={handleClickCloseDelete} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default AjoutBanniere;
