export const columns_bds_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "NOM",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
  {
    name: "PRODUITS",
    width: "20%",
    align: "left",
    type: "number",
    original_name: "count",
  },
  {
    name: "CRÉE",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "A JOUR LE",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
export const columns_bds_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "NAME",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
  {
    name: "PRODUCTS",
    width: "20%",
    align: "left",
    type: "number",
    original_name: "count",
  },
  {
    name: "CREATED",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "UPDATED AT",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
export const columns_bds_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "NOMBRE",
    width: "20%",
    align: "left",
    type: "string",
    original_name: "name",
  },
  {
    name: "PRODUCTOS",
    width: "20%",
    align: "left",
    type: "number",
    original_name: "count",
  },
  {
    name: "CREADO",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "created_at",
  },
  {
    name: "ACTUALIZADO EN",
    width: "20%",
    align: "left",
    type: "date",
    original_name: "updated_at",
  },
];
