import { Alert, Box, Button, IconButton, List, ListItem, Modal, TextField, Typography } from "@mui/material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { colors } from "../../../theme/colors";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { useLogin } from "../../../hooks/useLogin";
import { useEffect, useState } from "react";
import { Urls } from "../../../api/Urls";
import { CloseOutlined } from "@mui/icons-material";

import Api from "../../../api/Api";
import Loading from "../../../components/ui/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { modalStyle } from "../../../theme/ModalStyle";
const DbEdit = () => {
  const { user } = useAuthContext();
  const access_token = user?.access;
  const refresh_token = user?.refresh;
  const [limit, setLimit] = useState({ state: true, number: 10 });
  const [modification, setModification] = useState({ type: "refresh", state: false, count: 0, data: null });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [deleteU, setDeleteU] = useState({ state: false, count: 0 });
  const [openUpdate, setOpenUpdate] = useState({ state: false, data: null });
  const [openDelete, setOpenDelete] = useState(false);
  const [success, setSuccess] = useState("");
  const [loadingDup, setLoadingDup] = useState(false);
  const [loadingDe, setLoadingDe] = useState(false);
  const [error, setError] = useState("");
  const lang = localStorage?.getItem("i18nextLng");
  const { refreshToken } = useLogin();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const initial_values = {
    name: data?.name,
  };
  const validationSchema = yup.object({
    name: yup?.string().required(t("validation_required")),
  });

  useEffect(() => {
    if (loading === false) {
      Api.get(Urls?.GET_BDS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          offset: 0,
          limit: limit?.number,
        },
      })

        .then((res) => {
          if (limit?.state !== false) {
            setLimit({ state: false, number: res?.data?.count });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.code === "token_not_valid") {
            refreshToken(refresh_token).then((res) => {
              const newState = { type: "refresh-dbs", state: !modification?.state, count: modification.count + 1 };
              setModification(newState);
            });
          } else {
            setError(err?.response?.data?.details);
          }
        });
    }
  }, [modification, limit, loading]);
  useEffect(() => {
    Api.get(Urls?.GetDbByID(id), {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          return res?.data;
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "refresh-data", state: !modification?.state, count: modification.count + 1 };
            setModification(newState);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  }, [modification]);
  useEffect(() => {
    if (deleteU.count === 1) {
      handleDelete();
    }
  }, [deleteU]);
  useEffect(() => {
    if (modification?.type === "submit") {
      handleSubmit(modification?.data);
    }
  }, [modification]);
  //HANDLE SBUMIT UPDATE DB
  const handleSubmit = () => {
    handleClickCloseUpdate();
    setError(null);
    setLoadingDup(true);
    let values = openUpdate?.data;
    Api.patch(
      Urls.UpdateDatabase(id),
      {
        name: values.name,
      },
      {
        headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setSuccess(t("forms.success.update"));
        setLoadingDup(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { type: "submit", state: !modification?.state, count: modification.count + 1, data: values };
            setModification(newState);
          });
        } else {
          handleError(err?.response?.data);
        }
        setLoadingDup(false);
      });
  };
  //HANDLE DELETE DB
  const handleDelete = () => {
    setLoadingDe(true);
    handleClickCloseDelete();
    Api.delete(Urls?.DeleteDbByID(id), {
      headers: { "Content-Type": "application/json", "Accept-Language": lang + "-" + lang, Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        navigate("/admin/gestion-des-bds/liste");
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            const newState = { state: !deleteU?.state, count: deleteU + 1 };
            setDeleteU(newState);
          });
        } else {
          handleError(err?.response?.data);
        }
      });
    setLoadingDe(false);
  };
  //HANDLE ERROR
  function handleError(error) {
    setError({
      name: error?.name,
    });
  }
  //HANDLE CLICK UPDATE DB
  const handleClickOpenUpdate = (values) => {
    setOpenUpdate({ state: true, data: values });
  };

  //HANDLE CLICK CLOSE UPDATE DB
  const handleClickCloseUpdate = () => {
    setOpenUpdate({ state: false, data: null });
  };

  //HANDLE CLICK DELETE DB
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  //HANDLE CLICK CLOSE DELETE DB
  const handleClickCloseDelete = () => {
    setOpenDelete(false);
  };
  return (
    <Box>
      {loading && <Loading />}
      {!loading && (
        <Formik initialValues={initial_values} validationSchema={validationSchema} enableReinitialize={true} onSubmit={(values) => handleClickOpenUpdate(values)}>
          {({ errors, setFieldValue, values }) => (
            <Form>
              <Box maxWidth="500px" display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Box sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} display="flex" alignItems="center" gap="24px">
                    <Box flex={1}>
                      <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
                        {t("forms.labels.new_db")}
                      </Typography>
                    </Box>
                    <Box>
                      <Field name="name" type="input" placeholder={t("forms.labels.new_db")} as={TextField} />
                      <ErrorMessage name="name" component={FormikErrorText} color={colors.main} width="250px" />
                    </Box>
                  </Box>
                </Box>

                {success && <Alert severity="success">{success}</Alert>}
                {error && (
                  <Alert severity="error">
                    <Box>
                      <List>
                        {error?.name && (
                          <ListItem>
                            {t("forms.labels.new_db")} : {error?.name}
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </Alert>
                )}
                <Box display="flex" alignItems="center" gap="10px">
                  <Button disabled={loadingDup} type="submit" variant="primary">
                    {t("forms.submit.save")}{" "}
                    {loadingDup ? (
                      <Box marginLeft="10px">
                        <Loading color="white" size={20} />
                      </Box>
                    ) : null}
                  </Button>
                  <Button disabled={loadingDe} onClick={handleClickOpenDelete} variant="primary">
                    {t("forms.submit.delete")}{" "}
                    {loadingDe ? (
                      <Box marginLeft="10px">
                        <Loading color="white" size={20} />
                      </Box>
                    ) : null}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      {/* modal confirmation update */}
      <Modal open={openUpdate?.state} onClose={handleClickCloseUpdate}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseUpdate}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.save")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.update")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="20px">
              <Button onClick={handleSubmit} variant="primary">
                {t("forms.submit.save")}
              </Button>
              <Button onClick={handleClickCloseUpdate} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* modal confirmation delete */}
      <Modal open={openDelete} onClose={handleClickCloseDelete}>
        <Box sx={modalStyle}>
          <Box position="absolute" top="2px" right="2px">
            <IconButton onClick={handleClickCloseDelete}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box textAlign="center">
              <Typography variant="text_lg_black">{t("forms.submit.delete")}</Typography>
            </Box>
            <Box textAlign="center">
              <Typography variant="text_md_black">{t("forms.messages.delete")}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" gap="24px">
              <Button onClick={handleDelete} variant="primary">
                {t("forms.submit.delete")}
              </Button>
              <Button onClick={handleClickCloseDelete} variant="primary">
                {t("forms.submit.close")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default DbEdit;
