export const employe_columns_fr = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "NOM",
    width: "20%",
    align: "center",
    type: "float",
    original_name: "last_name",
  },
  {
    name: "PRENOM",
    width: "15%",
    align: "center",
    type: "string",
    original_name: "first_name",
  },
  {
    name: "NOM D'UTILISATEUR",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "username",
  },
  {
    name: "EMAIL",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "email",
  },
];
export const employe_columns_en = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "LAST NAME",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "last_name",
  },
  {
    name: "FIRST NAME",
    width: "15%",
    align: "center",
    type: "string",
    original_name: "first_name",
  },
  {
    name: "USERNAME",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "username",
  },
  {
    name: "EMAIL",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "email",
  },
];
export const employe_columns_es = [
  {
    name: "ID",
    width: "0%",
    align: "center",
    type: "float",
    original_name: "id",
  },
  {
    name: "NOMBRE",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "last_name",
  },
  {
    name: "APELLIDO",
    width: "15%",
    align: "center",
    type: "string",
    original_name: "first_name",
  },
  {
    name: "NOMBRE DE USUARIO",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "username",
  },
  {
    name: "CORREO ELECTRÓNICO",
    width: "20%",
    align: "center",
    type: "string",
    original_name: "email",
  },
];
