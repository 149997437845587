import { Alert, Autocomplete, Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, Typography } from "@mui/material";
import StyledTableComponent from "../../../components/ui/StyledTableComponent";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { Urls } from "../../../api/Urls";
import { useLogin } from "../../../hooks/useLogin";
import { columns_produits_en, columns_produits_es, columns_produits_fr } from "../../../columns/produitLanceurAlerteColumns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { colors } from "../../../theme/colors";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import debounce from "debounce";
const RecallList = () => {
  const { user } = useAuthContext();
  let access_token = user?.access;
  const navigate = useNavigate();
  let refresh_token = user?.refresh;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();
  const { refreshToken } = useLogin();
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [modification, setModification] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [sorting, setSorting] = useState({
    name: "",
    direction: "",
  });
  const [upc, setUpc] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [searchUpc, setSearchUpc] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [columns, setColumns] = useState([]);
  const lang = localStorage.getItem("i18nextLng");
  useEffect(() => {
    setColumns(lang === "fr" ? columns_produits_fr : lang === "en" ? columns_produits_en : lang === "es" ? columns_produits_es : []);
  }, []);
  useEffect(() => {
    setLoading(true);
    Api.get(Urls?.GET_RECALLED_PRODUCTS, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        offset: 0,
        limit: 25,
        ...(upc && { upc: upc }),
        ...(isOwner && { is_owner: isOwner }),
        ...(sorting?.name && { order_by: sorting?.name }),
        ...(sorting?.direction && { order_direction: sorting?.direction === "up" ? "asc" : "desc" }),
      },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            let note = element?.note?.substring(0, 100) + (element?.note?.length > 100 ? "..." : "");
            element = { id: element?.id, created_at: element?.created_at, upc: element?.upc, designation: element.designation, format: element.format, note: note };
            console.log(element);

            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
        setLoadingData(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setRefresh(!refresh);
          });
        } else {
          setError(err?.response?.data?.details);
          setLoadingData(false);
        }
        setLoading(false);
      });
  }, [refresh, isOwner, searchUpc, sorting]);

  const handlePagination = (url) => {
    Api.get(url, {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })

      .then((res) => {
        setData((data) => {
          let array = [];
          let d = res?.data?.results;
          for (let index = 0; index < d.length; index++) {
            let element = d[index];
            let note = element?.note?.substring(0, 100) + (element?.note?.length > 100 ? "..." : "");
            element = { id: element?.id, created_at: element?.created_at, upc: element?.upc, designation: element.designation, format: element.format, note: note };
            array.push(element);
          }
          return array;
        });
        setResponse(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.code === "token_not_valid") {
          refreshToken(refresh_token).then((res) => {
            console.log(res, "new user");
            setModification(!modification);
          });
        } else {
          setError(err?.response?.data?.details);
        }
        setLoading(false);
      });
  };

  const Buttons = ({ id }) => {
    return (
      <>
        <IconButton onClick={() => navigate("/lanceur-alerte/gestion-des-produits/rappel/liste/" + id)}>
          <ReadMoreIcon sx={{ color: `${colors.main}` }} />
        </IconButton>
      </>
    );
  };
  const Pagination = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end">
        {response?.previous && (
          <IconButton onClick={() => handlePagination(response?.previous)}>
            <ArrowLeft sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
        <Box>
          <Typography variant="text_md_black">{response?.count}</Typography>
        </Box>
        {response?.next && (
          <IconButton onClick={() => handlePagination(response?.next)}>
            <ArrowRight sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );
  };
  //debounced search
  const debouncedSearch = React.useMemo(() => {
    return debounce((upc) => {
      setSearchUpc(upc);
    }, 700);
  }, []);
  useEffect(() => {
    debouncedSearch(upc);
  }, [upc]);
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box maxWidth="500px" display="flex" flexDirection="column" gap="10px">
        <Box display="flex" gap="10px" alignItems="center">
          <Typography variant="text_md_white" fontWeight="600" color={colors?.main}>
            UPC
          </Typography>
          <Box>
            <TextField placeholder="UPC" type="number" onChange={(e) => setUpc(e.target?.value)} value={upc} />
          </Box>{" "}
        </Box>

        <FormControlLabel
          sx={{
            fontWeight: "600",
            color: colors?.main,
            fontSize: "18px",
          }}
          control={
            <Checkbox
              checked={isOwner}
              value={isOwner}
              onChange={(e) => {
                setIsOwner(e.target?.checked);
              }}
            />
          }
          label={t("forms.labels.by_me")}
        />
      </Box>

      {error && !loading && <Alert severity="error">{error}</Alert>}
      {!error && !loading && <StyledTableComponent sorting={sorting} setSorting={setSorting} setData={setData} data={data} columns={columns} Buttons={Buttons} pagination={<Pagination />} />}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
export default RecallList;
